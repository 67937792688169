import { IoMdClose as Close } from 'react-icons/io';
import { BiCircle as Circle } from 'react-icons/bi';

function CircleOrX({ firstCondition, icon }) {
  return (
    <>
      {firstCondition &&
        (icon === 'circle' ? (
          <Circle
            style={{
              fontSize: '58px',
              color: '#2797BA',
              filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
              strokeWidth: '1px',
            }}
          />
        ) : (
          <Close
            style={{
              fontSize: '63px',
              color: '#F44336',
              filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
              strokeWidth: '27px',
            }}
          />
        ))}
    </>
  );
}

export default CircleOrX;
