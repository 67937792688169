import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Main from '@views/Main';
import Resources from '@views/Resources';
import Games from '@views/Games';
import Areia from '@views/Areia';
import AMAForm from '@views/AMAForm';
import AMAProfessionalForm from '@views/AMAForm/ProfessionalForm';
import ProblemSize from '@views/ProblemSize';
import CirandaTribos from '@views/Ciranda/Tribos';
import CirandaPessoas from '@views/Ciranda/Pessoas';
import TOCJudgement from '@views/TOCJudgement';
import PassiveAgressive from '@views/PassiveAgressive';
import CatusFlower from '@views/CactusFlower';
import MountTheEmotion from '@views/MountTheEmotion';
import CauseEffect from '@views/CauseEffect';
import SelfEvaluation from '@views/SelfEvaluation';
import ShowTheWay from '@views/ShowTheWay';
import Parking from '@views/Parking';
import LoveMyself from '@views/LoveMyself';
import VaiEncarar from '@views/VaiEncarar';
import GoodVsBad from '@views/GoodVsBad';
import ExcessiveScreens from '@views/ExcessiveScreens';
import EmotionThermometer from '@views/EmotionThermometer';
import RelateTheColors from '@views/RelateTheColors';
import Arrows from '@views/Arrows';
import HexagonalDomino from '@views/HexagonalDomino';
import Domino from '@views/Domino';
import LinkThePlanes from '@views/LinkThePlanes';
import FoodGroup from '@views/FoodGroup';
import Checkers from '@views/Checkers';
import Chess from '@views/Chess';
import Draw from '@views/Draw';
import WheelOfLife from '@views/WheelOfLife';
import ConnectTheCubes from '@views/ConectTheCubes';
import JogoDaVelha from '@views/JogoDaVelha';
import FindTheTen from '@views/FindTheTen';
import Stop from '@views/Stop';

export default function Routes() {
  return (
    <Switch>
      <Route
        path="/"
        exact
        component={() => {
          window.location.href = 'https://www.terapiainterativa.com.br';
          return null;
        }}
      />
      <Route path={'/:os/:token'} exact component={Main} />
      <Route path={'/:os/:token/:ambiente'} exact component={Main} />
      <Route path="/recursos/profissional/:os/:token" component={Resources} />
      <Route path="/jogos/profissional/:os/:token" component={Games} />
      <Route path="/jogos/paciente/:os/:token" component={Games} />
      <Route path="/jogo-de-areia/profissional/:os/:token" component={Areia} />
      <Route path="/jogo-de-areia/paciente/:os/:token" component={Areia} />
      <Route path="/animal-musica-amuleto/paciente/:os/:token" component={AMAForm} />
      <Route
        path="/animal-musica-amuleto/profissional/:os/:token"
        component={AMAProfessionalForm}
      />
      <Route
        path="/o-tamanho-do-meu-problema/profissional/:os/:token"
        component={ProblemSize}
      />
      <Route
        path="/o-tamanho-do-meu-problema/paciente/:os/:token"
        component={ProblemSize}
      />
      <Route
        path="/ciranda-das-tribos/profissional/:os/:token"
        component={CirandaTribos}
      />
      <Route path="/ciranda-das-tribos/paciente/:os/:token" component={CirandaTribos} />
      <Route
        path="/ciranda-de-pessoas/profissional/:os/:token"
        component={CirandaPessoas}
      />
      <Route path="/ciranda-de-pessoas/paciente/:os/:token" component={CirandaPessoas} />
      <Route path="/julgamento-do-toc/profissional/:os/:token" component={TOCJudgement} />
      <Route path="/julgamento-do-toc/paciente/:os/:token" component={TOCJudgement} />
      <Route path="/a-flor-do-cacto/profissional/:os/:token" component={CatusFlower} />
      <Route path="/a-flor-do-cacto/paciente/:os/:token" component={CatusFlower} />
      <Route path="/monte-a-emocao/profissional/:os/:token" component={MountTheEmotion} />
      <Route path="/monte-a-emocao/paciente/:os/:token" component={MountTheEmotion} />
      <Route path="/causa-e-efeito/profissional/:os/:token" component={CauseEffect} />
      <Route path="/causa-e-efeito/paciente/:os/:token" component={CauseEffect} />
      <Route path="/autoavaliacao/profissional/:os/:token" component={SelfEvaluation} />
      <Route path="/autoavaliacao/paciente/:os/:token" component={SelfEvaluation} />
      <Route path="/mostre-o-caminho/profissional/:os/:token" component={ShowTheWay} />
      <Route path="/mostre-o-caminho/paciente/:os/:token" component={ShowTheWay} />
      <Route path="/estacionamento/profissional/:os/:token" component={Parking} />
      <Route path="/estacionamento/paciente/:os/:token" component={Parking} />
      <Route path="/me-amo-porque/profissional/:os/:token" component={LoveMyself} />
      <Route path="/me-amo-porque/paciente/:os/:token" component={LoveMyself} />
      <Route path="/vai-encarar/profissional/:os/:token" component={VaiEncarar} />
      <Route path="/vai-encarar/paciente/:os/:token" component={VaiEncarar} />
      <Route
        path="/bom-x-mau-comportamento/profissional/:os/:token"
        component={GoodVsBad}
      />
      <Route path="/bom-x-mau-comportamento/paciente/:os/:token" component={GoodVsBad} />
      <Route
        path="/excesso-de-telas/profissional/:os/:token"
        component={ExcessiveScreens}
      />
      <Route path="/excesso-de-telas/paciente/:os/:token" component={ExcessiveScreens} />
      <Route
        path="/termometro-das-emocoes/profissional/:os/:token"
        component={EmotionThermometer}
      />
      <Route
        path="/termometro-das-emocoes/paciente/:os/:token"
        component={EmotionThermometer}
      />
      <Route
        path="/relacione-as-cores/profissional/:os/:token"
        S
        component={RelateTheColors}
      />
      <Route path="/relacione-as-cores/paciente/:os/:token" component={RelateTheColors} />
      <Route path="/setas/profissional/:os/:token" component={Arrows} />
      <Route path="/setas/paciente/:os/:token" component={Arrows} />
      <Route
        path="/domino-hexagonal/profissional/:os/:token"
        component={HexagonalDomino}
      />
      <Route path="/domino-hexagonal/paciente/:os/:token" component={HexagonalDomino} />
      <Route path="/ligue-os-avioes/profissional/:os/:token" component={LinkThePlanes} />
      <Route path="/ligue-os-avioes/paciente/:os/:token" component={LinkThePlanes} />
      <Route path="/grupo-de-alimentos/profissional/:os/:token" component={FoodGroup} />
      <Route path="/grupo-de-alimentos/paciente/:os/:token" component={FoodGroup} />
      <Route path="/jogo-de-damas/profissional/:os/:token" component={Checkers} />
      <Route path="/jogo-de-damas/paciente/:os/:token" component={Checkers} />
      <Route path="/jogo-de-xadrez/profissional/:os/:token" component={Chess} />
      <Route path="/jogo-de-xadrez/paciente/:os/:token" component={Chess} />

      <Route path="/domino/profissional/:os/:token" component={Domino} />
      <Route path="/domino/paciente/:os/:token" component={Domino} />

      <Route path="/quadro-de-desenhos/profissional/:os/:token" component={Draw} />
      <Route path="/quadro-de-desenhos/paciente/:os/:token" component={Draw} />
      <Route path="/roda-da-vida/profissional/:os/:token" component={WheelOfLife} />
      <Route path="/roda-da-vida/paciente/:os/:token" component={WheelOfLife} />
      <Route
        path="/comunicacao-assertiva-passiva-e-agressiva/profissional/:os/:token"
        component={PassiveAgressive}
      />
      <Route
        path="/comunicacao-assertiva-passiva-e-agressiva/paciente/:os/:token"
        component={PassiveAgressive}
      />
      <Route path="/ligue-os-cubos/profissional/:os/:token" component={ConnectTheCubes} />
      <Route path="/ligue-os-cubos/paciente/:os/:token" component={ConnectTheCubes} />
      <Route path="/jogo-da-velha/profissional/:os/:token" component={JogoDaVelha} />
      <Route path="/jogo-da-velha/paciente/:os/:token" component={JogoDaVelha} />
      <Route path="/ache-o-dez/profissional/:os/:token" component={FindTheTen} />
      <Route path="/ache-o-dez/paciente/:os/:token" component={FindTheTen} />
      <Route path="/stop/profissional/:os/:token" component={Stop} />
      <Route path="/stop/paciente/:os/:token" component={Stop} />
    </Switch>
  );
}
