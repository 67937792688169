import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 38px;
    font-family: 'Lobster', cursive;
    font-weight: 500;
    margin-bottom: 0;
    color: #1c344e;
  }
`;

export const CubesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 36px;
  width: 100%;
`;

export const CubeWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 160px;
  margin-bottom: 24px;
`;

export const NumberInput = styled.input`
  text-align: center;
  width: 24px;
  height: 26px;
  border-radius: 4px;
  border: ${(props) =>
    props.answerCubes ? '1px solid black' : '1px solid #ccd1d3'};
  color: #4c5153;
  background: ${(props) => (props.answerCubes ? '#ffffff' : '#ccd1d3')};
  font-size: 14px;
  font-weight: 600;
`;

export const SaveArea = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 24px 0 8px;
  width: 100%;
  flex-wrap: wrap;
`;
