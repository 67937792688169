import { useState, useRef, useEffect, useMemo } from 'react';
import domtoimage from 'dom-to-image';

import { Thermomether, ThermometherTitle } from '@assets';

import {
  modalOpen,
  modalClose,
  handleBackHome,
  sendArquivo,
  startSocket,
  downloadURI,
  DataURL,
} from '@utils';

import {
  ModalAddOps,
  ModalAlert,
  SecondaryButton,
  TertiaryButton,
  ModalConfirm,
} from '@components';

import {
  GlobalStyle,
  Container,
  ThermometerBox,
  EmotionButton,
  ThermometerContainer,
  SaveArea,
  QuestionsContainer,
  Question,
  ThermometerTitle,
  HoverBox,
} from './styles';

function EmotionThermometer(props) {
  const {
    nome_recurso_cod,
    typeUser,
    os,
    token,
    tokenSocketIo,
    ambiente,
  } = DataURL(props);

  const [yourID, setYourID] = useState();
  const socketRef = useRef();

  //Modal variables
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenAlert, setIsOpenAlert] = useState(false);
  const [modalCleanIsOpen, setModalCleanIsOpen] = useState(false);
  const [modalCloseIsOpen, setModalCloseIsOpen] = useState(false);

  //thermometer variables
  const emotions = useMemo(() => {
    return [
      { name: 'EXPLOSIVO', color: '#83171a' },
      { name: 'DESCONTROLADO', color: '#f44336' },
      { name: 'FURIOSO', color: '#FF4F19' },
      { name: 'MUITO IRRITADO', color: '#f4d400' },
      { name: 'IRRITADO', color: '#28a745' },
      { name: 'INCOMODADO', color: '#1c7330' },
      { name: 'INQUIETO', color: '#2797ba' },
      { name: 'TOTALMENTE EM PAZ', color: '#114655' },
    ];
  }, []);
  const [inputObject, setInputObject] = useState({
    q1: '',
    q2: '',
    q3: '',
    q4: '',
    q5: '',
  });
  const [emotionString, setEmotionString] = useState('TOTALMENTE EM PAZ');

  const [isPrinting, setIsPrinting] = useState(false);
  const titleColor = useRef();

  const handleExport = () => {
    setIsPrinting(true);
    setTimeout(() => {
      domtoimage
        .toPng(document.getElementById('divIdToPrint'))
        .then(function (dataUrl) {
          const nome_paciente = localStorage
            .getItem('@nome_paciente')
            .replaceAll(' ', '_');
          const filename = `TerapiaInterativa-${nome_recurso_cod}-${nome_paciente}.png`;
          downloadURI(
            dataUrl,
            filename,
            sendArquivo,
            os,
            token,
            nome_recurso_cod
          );
          setIsPrinting(false);
        })
        .catch(function (error) {
          console.error('oops, something went wrong!', error);
        });
    }, 500);
  };

  const salvarFim = () => {
    setIsOpen(true);
  };

  function handleInputOnChange(e, key) {
    const newObj = { ...inputObject };

    newObj[key] = e.target.value;

    setInputObject(newObj);
    sendInformation(newObj, 'input');
  }

  function handleButtonOnClick(emotion, color) {
    setEmotionString(emotion);
    sendInformation([emotion, color], 'emotion');

    titleColor.current.style.color = color;
  }

  function handleRestartThermometer() {
    handleButtonOnClick('TOTALMENTE EM PAZ', '#114655');
  }

  function handleClean() {
    const newObj = {
      q1: '',
      q2: '',
      q3: '',
      q4: '',
      q5: '',
    };

    handleRestartThermometer();
    changeInputValue(newObj);
    setInputObject(newObj);
    setModalCleanIsOpen(false);

    sendInformation(newObj, 'input');
  }

  function sendInformation(information, messageType) {
    const messageObject = {
      body: {
        information,
        messageType,
      },
      id: yourID,
      typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  function changeInputValue(obj) {
    for (let i = 1; i < 6; i++) {
      document.getElementById(`q${i}`).value = obj[`q${i}`];
    }
  }

  function receivedMessage(message) {
    const isInfoInput = message.body.messageType === 'input';
    const information = message.body.information;

    if (isInfoInput) {
      setInputObject(information);
      changeInputValue(information);
    } else {
      setEmotionString(information[0]);
      titleColor.current.style.color = information[1];
    }
  }

  useEffect(() => {
    startSocket(socketRef, setYourID, receivedMessage, tokenSocketIo, typeUser);
    if (typeUser === 'profissional') {
      modalOpen(nome_recurso_cod, tokenSocketIo);
    }
  }, []);

  return (
    <Container id="divIdToPrint">
      <GlobalStyle />
      <ThermometerContainer>
        <SaveArea>
          {typeUser === 'profissional' && !isPrinting && (
            <TertiaryButton mobile onClick={salvarFim}>
              Salvar
            </TertiaryButton>
          )}
          {typeUser === 'profissional' && !isPrinting && (
            <SecondaryButton mobile onClick={() => setIsOpenAlert(true)}>
              Instruções
            </SecondaryButton>
          )}
          {typeUser === 'profissional' && !isPrinting && (
            <SecondaryButton mobile onClick={() => setModalCloseIsOpen(true)}>
              Fechar
            </SecondaryButton>
          )}
        </SaveArea>

        <ThermometerBox
          style={{ backgroundImage: `url(${Thermomether})` }}
          alt="termometro"
        >
          {emotions.map((emotion) => (
            <EmotionButton
              background={emotion.color}
              onClick={() => handleButtonOnClick(emotion.name, emotion.color)}
            >
              {emotionString === emotion.name && (
                <HoverBox>
                  <h2>Como estou me sentindo</h2>
                  <div />
                </HoverBox>
              )}
              {emotion.name}
            </EmotionButton>
          ))}
          <img src={ThermometherTitle} alt="TERMÔMETRO DA BRABEZA" />
        </ThermometerBox>
      </ThermometerContainer>
      <QuestionsContainer>
        <ThermometerTitle>
          <h1>COMO ESTOU ME SENTINDO: </h1>
          <h2 ref={titleColor}>{emotionString}</h2>
        </ThermometerTitle>
        <Question>
          <p>1. Em que situações da sua vida você se sente assim?</p>
          <textarea
            placeholder="Clique para digitar"
            maxLength={174}
            id="q1"
            onChange={(e) => handleInputOnChange(e, 'q1')}
          />
        </Question>
        <Question>
          <p>2. Com que frequência esse sentimento aparece?</p>
          <textarea
            maxLength={174}
            placeholder="Clique para digitar"
            id="q2"
            onChange={(e) => handleInputOnChange(e, 'q2')}
          />
        </Question>
        <Question>
          <p>3. O que você costuma fazer quando se sente dessa forma?</p>
          <textarea
            maxLength={174}
            placeholder="Clique para digitar"
            id="q3"
            onChange={(e) => handleInputOnChange(e, 'q3')}
          />
        </Question>
        <Question>
          <p>4. Quais são os resultados desse seu comportamento?</p>
          <textarea
            maxLength={174}
            placeholder="Clique para digitar"
            id="q4"
            onChange={(e) => handleInputOnChange(e, 'q4')}
          />
        </Question>
        <Question>
          <p>5. O que você poderia mudar para melhorar esses resultados?</p>
          <textarea
            maxLength={174}
            placeholder="Clique para digitar"
            id="q5"
            onChange={(e) => handleInputOnChange(e, 'q5')}
          />
        </Question>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '15px',
          }}
        >
          {typeUser === 'profissional' && !isPrinting && (
            <SecondaryButton mobile onClick={() => setModalCleanIsOpen(true)}>
              Reiniciar
            </SecondaryButton>
          )}
        </div>
      </QuestionsContainer>
      <ModalAddOps
        generatePdf={handleExport}
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        token={token}
      />
      <ModalAlert
        modalIsOpen={modalIsOpenAlert}
        setIsOpen={setIsOpenAlert}
        token={token}
        props={props}
        title={'Termômetro das emoções'}
        recurso={nome_recurso_cod}
        bt01Txt={'Fechar'}
      />
      <ModalConfirm
        title="Limpar o recurso"
        description="Deseja realmente reiniciar o recurso?"
        modalIsOpen={modalCleanIsOpen}
        setIsOpen={setModalCleanIsOpen}
        confirmButtonText="reiniciar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCleanIsOpen(false)}
        onConfirm={handleClean}
      />
      <ModalConfirm
        title="Fechar o recurso"
        description="Deseja realmente fechar o recurso?"
        modalIsOpen={modalCloseIsOpen}
        setIsOpen={setModalCloseIsOpen}
        confirmButtonText="Fechar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCloseIsOpen(false)}
        onConfirm={() =>
          handleBackHome(
            modalClose,
            nome_recurso_cod,
            typeUser,
            props,
            os,
            token,
            ambiente
          )
        }
      />
    </Container>
  );
}

export default EmotionThermometer;
