import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { cactus, jigsaw, x_icon } from '../../assets';

import {
  ModalReadMore,
  ModalStartResource,
  ModalAlert,
} from '../../components';

import { getListRecursos } from '../../services/apiService';

/** CSS */
import {
  Container,
  Box,
  Cardcontainer,
  Title,
  CardBody,
  DescriptionWrapper,
  ButtonBox,
  ImageBox,
  ButtonSave,
  TitleBox,
  TopBox,
} from './styles';

/** Imagens */

function Main(props) {
  const params = useParams();

  const url = props.location.pathname.split('/');
  const urldebug =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjZlODM0ODQ4LWRlY2ItNDQ5MS1hNjRkLTllNDgyNjAxOTliZCIsImlhdCI6MTYxMjc5MTUwMiwiZXhwIjoxOTI4MzY3NTAyfQ.mcS6GKpGWw7YWjzEBxvEQYlPNq9gunRKe1Jswe__5vw';
  const os = params.os || 'terapiaplay';
  const token = params.token || urldebug;
  const ambiente = url[3] || '';

  const rand = () => Math.random(0).toString(36).substr(2);
  const tokenU = (length) =>
    (rand() + rand() + rand() + rand()).substr(0, length);

  const tokenSocket = tokenU(10);

  const [modalIsOpenAlert, setIsOpenAlert] = useState(
    ambiente === 'salavirtual' ? false : true
  );
  const [readMoreModal, setReadMoreModal] = useState(false);
  const [readMoreContent, setReadMoreContent] = useState({});
  const [startResourceContent, setStartResourceContent] = useState({});
  const [startResourceModal, setStartResourceModal] = useState(false);
  const [jogos, setJogos] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const listRecursos = async () => {
    await getListRecursos(os, token)
      .then((resp) => {
        setJogos(resp.data.records);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLinkTo = () => {
    return (window.location.href = `https://salavirtual.terapiaplay.com.br/entrar?token=${token}`);
  };

  const searchData = () => {
    const results = jogos.filter((j) =>
      j.nome_recurso.toLowerCase().includes(searchTerm)
    );
    setSearchResults(results);
  };

  const modalClose = () => {
    if (window && window.parent) {
      window.parent.postMessage(
        {
          action: 'fechar',
        },
        '*'
      );
    }
  };

  const handleBackHome = () => {
    const r = window.confirm('Gostaria realmente de encerrar os recursos?');
    if (r === true) {
      modalClose();
    }
  };

  useEffect(() => {
    listRecursos();
    document.title = 'Terapia Interativa';
  }, []);

  useEffect(() => {
    searchData();
  }, [jogos, searchTerm]);

  const games = {
    link: 'jogos',
    nome_recurso: 'Jogos',
    publico_alvo: 'crianças, adultos com dificuldade de expressão',
  };

  return (
    <>
      <Container
        backgroundColor={ambiente === 'salavirtual' ? '#3D3D3D' : '#ffffff'}
      >
        <TopBox>
          <ButtonSave color={'#ff0000'} onClick={handleBackHome}>
            {ambiente === 'salavirtual' && (
              <>
                <button>Fechar</button>
                <img src={x_icon} alt="x icon" />
              </>
            )}
          </ButtonSave>
          <TitleBox
            HeaderColor={ambiente === 'salavirtual' ? '#F5F5F5' : '#333333'}
            ParagraphColor={ambiente === 'salavirtual' ? '#E8E9E9' : '#4C5153'}
            HeaderWidth={ambiente === 'salavirtual' ? '80%' : '100%'}
          >
            <div>
              <h2>Escolha uma opção para continuar</h2>
              <p>
                Para continuar leia as descrições escolha uma das opções abaixo.
              </p>
            </div>
          </TitleBox>
        </TopBox>
        <Box>
          <Cardcontainer recursos>
            <Title>Recursos terapêuticos</Title>
            <CardBody>
              <ImageBox cactus>
                <img src={cactus} alt="Logo cacto" />
              </ImageBox>
              <DescriptionWrapper>
                <p>
                  Os recursos terapêuticos são como: A flor do cacto, caixa de
                  areia e muito mais
                </p>
                <ButtonBox>
                  <button
                    onClick={() => {
                      window.location.href = `/recursos/profissional/${os}/${token}/${ambiente}`;
                    }}
                  >
                    Escolher recurso terapêutico
                  </button>
                </ButtonBox>
              </DescriptionWrapper>
            </CardBody>
          </Cardcontainer>
          <Cardcontainer>
            <Title>Jogos online</Title>
            <CardBody>
              <ImageBox>
                <img src={jigsaw} alt="Logo quebra-cabeça" />
              </ImageBox>
              <DescriptionWrapper>
                <p>Os jogos online são : dominó, xadrez e damas!</p>
                <ButtonBox>
                  <button
                    onClick={() => {
                      setStartResourceModal(true);
                      setStartResourceContent(games);
                    }}
                  >
                    Escolher jogo
                  </button>
                </ButtonBox>
              </DescriptionWrapper>
            </CardBody>
          </Cardcontainer>
        </Box>
        <ModalAlert
          modalIsOpen={modalIsOpenAlert}
          setIsOpen={setIsOpenAlert}
          token={token}
          props={props}
          title={'Saiba mais'}
          type={'os'}
          bt01Txt={'Continuar apenas nos jogos'}
        />
        <ModalReadMore
          modalIsOpen={readMoreModal}
          setIsOpen={setReadMoreModal}
          content={readMoreContent}
          os={os}
          token={token}
          ambiente={ambiente}
          setStartResourceContent={setStartResourceContent}
          setStartResourceModal={setStartResourceModal}
        />
      </Container>
      <ModalStartResource
        isOpen={startResourceModal}
        setIsOpen={setStartResourceModal}
        content={startResourceContent}
        os={os}
        token={token}
        ambiente={ambiente}
        tokenSocket={tokenSocket}
      />
    </>
  );
}

export default Main;
