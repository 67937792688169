import { useState, useRef, useEffect, Fragment } from 'react';

import { ColorPicker } from '../../components';

import { Stage, Layer, Line, Rect, Circle, Text } from 'react-konva';

import {
  FaPencilAlt,
  FaUndo,
  FaEraser,
  FaSquare,
  FaCircle,
} from 'react-icons/fa';
import { BsSlash } from 'react-icons/bs';
import { BiRectangle, BiCircle, BiText } from 'react-icons/bi';

import {
  modalOpen,
  modalClose,
  handleBackHome,
  sendArquivo,
  startSocket,
  downloadURI,
  DataURL,
} from '../../utils';

import {
  ModalAddOps,
  ModalAlert,
  SecondaryButton,
  ModalConfirm,
} from '../../components';

import {
  Container,
  StageBox,
  ToolsContainer,
  ToolButton,
  Slider,
  SaveArea,
  GlobalStyle,
  ToolsButtonContainer,
  ToolsWrapper,
  ColorPickerContainer,
  Display,
  TextInputBox,
} from './styles';

function Draw(props) {
  const {
    nome_recurso_cod,
    typeUser,
    os,
    token,
    tokenSocketIo,
    ambiente,
  } = DataURL(props);

  //Modal variables
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenAlert, setIsOpenAlert] = useState(false);
  const [modalIsOpenAlertText, setIsOpenAlertText] = useState(false);
  const [modalCleanIsOpen, setModalCleanIsOpen] = useState(false);
  const [modalCloseIsOpen, setModalCloseIsOpen] = useState(false);

  //socket variables
  const [yourID, setYourID] = useState();
  const socketRef = useRef();

  // width of konva screen
  const innerWidthSize = window.innerWidth > 710 ? 710 : window.innerWidth;
  const proportionalStageHeight = (innerWidthSize * 535) / 710;
  const scaleX = innerWidthSize / 710;
  const scaleY = proportionalStageHeight / 535;
  const stageRef = useRef();
  const layerRef = useRef();

  // konva related variables
  const [toolsProfessional, setToolsProfessional] = useState([]);
  const [toolsPatient, setToolsPatient] = useState([]);
  const [tool, setTool] = useState('pen');
  const [color, setColor] = useState('#000000');
  const [sliderStroke, setSliderStroke] = useState(20);
  const isDrawing = useRef(false);
  const [text, setText] = useState('');

  //shape related states and clickFunctions
  const [currentShape, setCurrentShape] = useState('');
  const [isShapeSelected, setIsShapeSelected] = useState(false);

  //sliderStroke function
  function handleSliderChange(e) {
    setSliderStroke(e.target.value);
  }

  //konva related functions

  function clearScreen() {
    setToolsProfessional([]);
    setToolsPatient([]);
    setModalCleanIsOpen(false);
    sendLinesArrays([], ['clear', 'all']);
  }

  function handleUndoLineOnClick() {
    const newLines =
      typeUser === 'profissional' ? [...toolsProfessional] : [...toolsPatient];
    newLines.pop();

    if (typeUser === 'profissional') {
      sendLinesArrays(newLines, ['undo', '']);
      setToolsProfessional(newLines);
    } else {
      sendLinesArrays(newLines, ['undo', '']);
      setToolsPatient(newLines);
    }
  }

  // --------------- draw handlers --------------- //
  function handleOnStartEvent(e, isMobile) {
    isDrawing.current = true;
    const pos = e.target.getStage().getPointerPosition();
    const points = isMobile ? [pos.x / scaleX, pos.y / scaleY] : [pos.x, pos.y];

    const newLine = {
      tool: tool,
      lineColor: color,
      lineStroke: sliderStroke,
      data: points,
      typeUser,
    };

    if (typeUser === 'profissional') {
      setToolsProfessional([...toolsProfessional, newLine]);
    } else {
      setToolsPatient([...toolsPatient, newLine]);
    }
  }

  function handleOnMoveEvent(e, isMobile) {
    // no drawing - skipping
    if (!isDrawing.current) {
      return;
    }

    const stage = e.target.getStage();
    const pos = stage.getPointerPosition();
    const points = isMobile ? [pos.x / scaleX, pos.y / scaleY] : [pos.x, pos.y];

    let lastLine;
    if (typeUser === 'profissional') {
      lastLine = toolsProfessional[toolsProfessional.length - 1];
    } else {
      lastLine = toolsPatient[toolsPatient.length - 1];
    }
    // add pos

    if (tool === 'penStraight') {
      for (let i = lastLine.data.length; i > 2; i--) {
        lastLine.data.pop();
      }
    }
    lastLine.data = lastLine.data.concat(points);

    // replace last

    if (typeUser === 'profissional') {
      toolsProfessional.splice(toolsProfessional.length - 1, 1, lastLine);
      setToolsProfessional(toolsProfessional.concat());
    } else {
      toolsPatient.splice(toolsPatient.length - 1, 1, lastLine);
      setToolsPatient(toolsPatient.concat());
    }
  }

  function handleMouseUp(e, isMobile) {
    isDrawing.current = false;

    const pos = e.target.getStage().getPointerPosition();
    const points = isMobile ? [pos.x / scaleX, pos.y / scaleY] : [pos.x, pos.y];

    let lastLine;
    if (typeUser === 'profissional') {
      lastLine = toolsProfessional[toolsProfessional.length - 1];
    } else {
      lastLine = toolsPatient[toolsPatient.length - 1];
    }
    // add pos
    lastLine.data = lastLine.data.concat(points);

    if (typeUser === 'profissional') {
      toolsProfessional.splice(toolsProfessional.length - 1, 1, lastLine);
      setToolsProfessional(toolsProfessional.concat());
      sendLinesArrays(lastLine, ['draw', 'lines']);
    } else {
      toolsPatient.splice(toolsPatient.length - 1, 1, lastLine);
      setToolsPatient(toolsPatient.concat());
      sendLinesArrays(lastLine, ['draw', 'lines']);
    }
  }

  // --------------------- shapes handlers ---------------------- //

  function handleMouseDownShapes(e) {
    isDrawing.current = true;

    if (!isDrawing.current) {
      return;
    }

    const pos = e.target.getStage().getPointerPosition();

    const newShape = {
      startPointX: pos.x / scaleX,
      startPointY: pos.y / scaleY,
      width: 0,
      height: 0,
      tool: tool,
      lineColor: color,
      lineStroke: sliderStroke,
      typeUser: typeUser,
    };

    if (typeUser === 'profissional') {
      setToolsProfessional([...toolsProfessional, newShape]);
    } else {
      setToolsPatient([...toolsPatient, newShape]);
    }
  }

  function handleMouseUpShapes() {
    isDrawing.current = false;

    if (currentShape === 'recs' || currentShape === 'recsFill') {
      if (typeUser === 'profissional') {
        sendLinesArrays(toolsProfessional[toolsProfessional.length - 1], [
          'draw',
          'recs',
        ]);
      } else {
        sendLinesArrays(toolsPatient[toolsPatient.length - 1], [
          'draw',
          'recs',
        ]);
      }
    } else if (currentShape === 'circles' || currentShape === 'circlesFill') {
      if (typeUser === 'profissional') {
        sendLinesArrays(toolsProfessional[toolsProfessional.length - 1], [
          'draw',
          'recs',
        ]);
      } else {
        sendLinesArrays(toolsPatient[toolsPatient.length - 1], [
          'draw',
          'recs',
        ]);
      }
    }
  }

  function handleMouseMoveCircle(point, draws, setDraws) {
    const currentCircle = draws[draws.length - 1];
    const startY = currentCircle.startPointY;
    const startX = currentCircle.startPointX;
    const currentY = point.y / scaleX;
    const currentX = point.x / scaleX;

    const diameter = Math.sqrt(
      Math.pow(currentX - startX, 2) + Math.pow(currentY - startY, 2)
    );

    const radius = diameter / 2;
    currentCircle.radius = radius;

    if (currentY < startY) {
      currentCircle.y = startY - radius;
    } else {
      currentCircle.y = startY + radius;
    }

    if (currentX < startX) {
      currentCircle.x = startX - radius;
    } else {
      currentCircle.x = startX + radius;
    }
    // replace last
    draws.splice(draws.length - 1, 1, currentCircle);
    setDraws(draws.concat());
  }

  function handleMouseMoveRect(point, draws, setDraws) {
    const currentRec = draws[draws.length - 1];

    currentRec.width = point.x / scaleX - currentRec.startPointX;
    currentRec.height = point.y / scaleX - currentRec.startPointY;

    // replace last
    draws.splice(draws.length - 1, 1, currentRec);
    setDraws(draws.concat());
  }

  function handleMouseMoveShapes(e) {
    // no drawing - skipping
    if (!isDrawing.current) {
      return;
    }
    const point = e.target.getStage().getPointerPosition();
    // handle  rectangle part

    if (currentShape === 'recs' || currentShape === 'recsFill') {
      if (typeUser === 'profissional') {
        handleMouseMoveRect(point, toolsProfessional, setToolsProfessional);
      } else {
        handleMouseMoveRect(point, toolsPatient, setToolsPatient);
      }
    } else if (currentShape === 'circles' || currentShape === 'circlesFill') {
      if (typeUser === 'profissional') {
        handleMouseMoveCircle(point, toolsProfessional, setToolsProfessional);
      } else {
        handleMouseMoveCircle(point, toolsPatient, setToolsPatient);
      }
    }
  }

  // text konva functions

  function onMouseDownText(e) {
    const pos = e.target.getStage().getPointerPosition();

    const newText = {
      x: pos.x / scaleX,
      y: pos.y / scaleY,
      tool: tool,
      fontSize: sliderStroke,
      textColor: color,
      typeUser: typeUser,
      text: text,
    };

    if (typeUser === 'profissional') {
      setToolsProfessional([...toolsProfessional, newText]);
      sendLinesArrays(newText, ['draw', 'lines']);
    } else {
      setToolsPatient([...toolsPatient, newText]);
      sendLinesArrays(newText, ['draw', 'lines']);
    }
  }

  //------------------------------------------//

  // socket related functions
  function sendLinesArrays(drawObject, messageType) {
    const messageObject = {
      body: {
        drawObject,
        messageType,
      },
      id: yourID,
      typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  function receivedMessage(message) {
    const typeOfToolRecieved = message.body.messageType;
    const recievedDrawObject = message.body.drawObject;
    const isMessageFromPatient = message.typeUser === 'paciente';

    switch (typeOfToolRecieved[0]) {
      case 'clear':
        setToolsProfessional([]);
        setToolsPatient([]);
        break;
      case 'undo':
        if (isMessageFromPatient) {
          setToolsProfessional(recievedDrawObject);
        } else {
          setToolsPatient(recievedDrawObject);
        }
        break;
      default:
        if (isMessageFromPatient) {
          setToolsProfessional((prevToolsProfessional) => [
            ...prevToolsProfessional,
            recievedDrawObject,
          ]);
        } else {
          setToolsPatient((prevToolsPatient) => [
            ...prevToolsPatient,
            recievedDrawObject,
          ]);
        }
    }
  }

  useEffect(() => {
    startSocket(socketRef, setYourID, receivedMessage, tokenSocketIo, typeUser);
    if (typeUser === 'profissional') {
      modalOpen(nome_recurso_cod, tokenSocketIo);
    }
  }, []);

  const salvarFim = () => {
    setIsOpen(true);
  };

  const handleExport = () => {
    setTimeout(() => {
      const uri = stageRef.current.toDataURL();
      const nome_paciente = localStorage
        .getItem('@nome_paciente')
        .replaceAll(' ', '_');
      const filename = `TerapiaInterativa-${nome_recurso_cod}-${nome_paciente}.png`;
      downloadURI(uri, filename, sendArquivo, os, token, nome_recurso_cod);
    }, 500);
  };

  return (
    <Container>
      <GlobalStyle />
      <ToolsContainer>
        <Slider>
          <input
            type="range"
            min={0}
            max={60}
            value={sliderStroke}
            onChange={(e) => handleSliderChange(e)}
          />
          {tool === 'pen' && <FaPencilAlt style={{ color: '#2797ba' }} />}
          {tool === 'eraser' && <FaEraser style={{ color: '#2797ba' }} />}
          {tool === 'recsFill' && <FaSquare style={{ color: '#2797ba' }} />}
          {tool === 'circlesFill' && <FaCircle style={{ color: '#2797ba' }} />}
          {tool === 'recs' && <BiRectangle style={{ color: '#2797ba' }} />}
          {tool === 'circle' && <BiCircle style={{ color: '#2797ba' }} />}
          {tool === 'penStraight' && (
            <BsSlash style={{ color: '#2797ba', transform: 'rotate(90deg)' }} />
          )}
          {tool === 'text' && <BiText style={{ color: '#2797ba' }} />}
        </Slider>
        <ToolsWrapper>
          <ToolsButtonContainer>
            <ToolButton
              onClick={() => {
                setTool('pen');
                setColor('#000000');
                setIsShapeSelected(false);
              }}
            >
              <FaPencilAlt />
            </ToolButton>

            <ToolButton
              onClick={() => {
                setTool('eraser');
                setColor('#ffffff');
                setIsShapeSelected(false);
              }}
            >
              <FaEraser />
            </ToolButton>

            <ToolButton
              onClick={() => {
                setIsShapeSelected(true);
                setCurrentShape('recs');
                setTool('recsFill');
                setColor('#000000');
              }}
            >
              <FaSquare />
            </ToolButton>

            <ToolButton
              onClick={() => {
                setIsShapeSelected(true);
                setCurrentShape('circles');
                setTool('circlesFill');
                setColor('#000000');
              }}
            >
              <FaCircle />
            </ToolButton>

            <ToolButton
              onClick={() => {
                setIsShapeSelected(true);
                setCurrentShape('recs');
                setTool('recs');
                setColor('#000000');
              }}
            >
              <BiRectangle />
            </ToolButton>

            <ToolButton
              onClick={() => {
                setIsShapeSelected(true);
                setCurrentShape('circles');
                setTool('circle');
                setColor('#000000');
              }}
            >
              <BiCircle />
            </ToolButton>

            <ToolButton
              onClick={() => {
                setTool('penStraight');
                setColor('#000000');
                setIsShapeSelected(false);
              }}
            >
              <BsSlash
                style={{ fontSize: '30px', transform: 'rotate(90deg)' }}
              />
            </ToolButton>

            <ToolButton
              onClick={() => {
                setTool('text');
                setColor('#000000');
                setIsShapeSelected(false);
              }}
            >
              <BiText style={{ fontSize: '24px' }} />
            </ToolButton>

            <ToolButton
              onClick={handleUndoLineOnClick}
              tooltipLeft="6px"
              tooltipLeftMobile="-6px"
              large
            >
              <FaUndo />
            </ToolButton>

            <ToolButton large onClick={() => setModalCleanIsOpen(true)}>
              Limpar
            </ToolButton>
          </ToolsButtonContainer>

          <ColorPickerContainer>
            <Display style={{ backgroundColor: color }} />
            <ColorPicker currentColor={color} setColor={setColor} />
          </ColorPickerContainer>
        </ToolsWrapper>
        {tool === 'text' && (
          <TextInputBox>
            <h3>Caixa de texto</h3>
            <textarea
              id="input"
              placeholder="Escreva aqui"
              onChange={(e) => setText(e.target.value)}
            />
            <div>
              <button
                onClick={() => (document.getElementById('input').value = '')}
              >
                Apagar
              </button>
              <button onClick={() => setIsOpenAlertText(true)}>
                Instruções
              </button>
            </div>
          </TextInputBox>
        )}
      </ToolsContainer>

      <StageBox>
        <Stage
          ref={stageRef}
          width={innerWidthSize}
          height={proportionalStageHeight}
          scaleX={scaleX}
          scaleY={scaleY}
          onMouseDown={
            isShapeSelected
              ? (e) => handleMouseDownShapes(e)
              : tool === 'text'
              ? null
              : (e) => handleOnStartEvent(e, false)
          }
          onMousemove={
            isShapeSelected
              ? (e) => handleMouseMoveShapes(e)
              : tool === 'text'
              ? null
              : (e) => handleOnMoveEvent(e, false)
          }
          onMouseup={
            isShapeSelected
              ? handleMouseUpShapes
              : tool === 'text'
              ? (e) => onMouseDownText(e)
              : (e) => handleMouseUp(e, false)
          }
          onTouchStart={
            isShapeSelected
              ? (e) => handleMouseDownShapes(e)
              : tool === 'text'
              ? null
              : (e) => handleOnStartEvent(e, true)
          }
          onTouchMove={
            isShapeSelected
              ? (e) => handleMouseMoveShapes(e)
              : tool === 'text'
              ? null
              : (e) => handleOnMoveEvent(e, true)
          }
          onTouchEnd={
            isShapeSelected
              ? handleMouseUpShapes
              : tool === 'text'
              ? (e) => onMouseDownText(e)
              : (e) => handleMouseUp(e, true)
          }
          style={{ border: '1px solid black', backgroundColor: '#fff' }}
        >
          <Layer ref={layerRef}>
            {typeUser === 'profissional' &&
              toolsProfessional.length > 0 &&
              toolsProfessional.map((line, index) => (
                <Fragment key={index}>
                  {(line.tool === 'pen' ||
                    line.tool === 'eraser' ||
                    line.tool === 'penStraight') && (
                    <Line
                      points={line.data}
                      stroke={
                        line.tool === 'eraser' ? '#ffffff' : line.lineColor
                      }
                      strokeWidth={line.lineStroke}
                      tension={0.5}
                      lineCap="round"
                      globalCompositeOperation={
                        line.tool === 'eraser'
                          ? 'destination-out'
                          : 'source-over'
                      }
                    />
                  )}
                  {line.tool === 'recs' && (
                    <Rect
                      x={Math.min(
                        line.startPointX,
                        line.startPointX + line.width
                      )}
                      y={Math.min(
                        line.startPointY,
                        line.startPointY + line.height
                      )}
                      width={Math.abs(line.width)}
                      height={Math.abs(line.height)}
                      fill={'rgb(0,0,0,0)'}
                      stroke={line.lineColor}
                      strokeWidth={line.lineStroke}
                    />
                  )}
                  {line.tool === 'recsFill' && (
                    <Rect
                      x={Math.min(
                        line.startPointX,
                        line.startPointX + line.width
                      )}
                      y={Math.min(
                        line.startPointY,
                        line.startPointY + line.height
                      )}
                      width={Math.abs(line.width)}
                      height={Math.abs(line.height)}
                      fill={line.lineColor}
                      stroke={line.lineColor}
                      strokeWidth={line.lineStroke}
                    />
                  )}
                  {line.tool === 'circle' && (
                    <Circle
                      x={line.x}
                      y={line.y}
                      radius={line.radius}
                      fill={'rgb(0,0,0,0)'}
                      stroke={line.lineColor}
                      strokeWidth={line.lineStroke}
                    />
                  )}
                  {line.tool === 'circlesFill' && (
                    <Circle
                      x={line.x}
                      y={line.y}
                      radius={line.radius}
                      fill={line.lineColor}
                      stroke={line.lineColor}
                      strokeWidth={line.lineStroke}
                    />
                  )}
                  {line.tool === 'text' && (
                    <Text
                      x={line.x}
                      y={line.y}
                      text={line.text}
                      stroke={line.textColor}
                      fontSize={line.fontSize}
                      fill={line.textColor}
                    />
                  )}
                </Fragment>
              ))}

            {typeUser === 'paciente' &&
              toolsPatient.length > 0 &&
              toolsPatient.map((line, index) => (
                <Fragment key={index}>
                  {(line.tool === 'pen' ||
                    line.tool === 'eraser' ||
                    line.tool === 'penStraight') && (
                    <Line
                      points={line.data}
                      stroke={
                        line.tool === 'eraser' ? '#ffffff' : line.lineColor
                      }
                      strokeWidth={line.lineStroke}
                      tension={0.5}
                      lineCap="round"
                      globalCompositeOperation={
                        line.tool === 'eraser'
                          ? 'destination-out'
                          : 'source-over'
                      }
                    />
                  )}
                  {line.tool === 'recs' && (
                    <Rect
                      x={Math.min(
                        line.startPointX,
                        line.startPointX + line.width
                      )}
                      y={Math.min(
                        line.startPointY,
                        line.startPointY + line.height
                      )}
                      width={Math.abs(line.width)}
                      height={Math.abs(line.height)}
                      fill={'rgb(0,0,0,0)'}
                      stroke={line.lineColor}
                      strokeWidth={line.lineStroke}
                    />
                  )}

                  {line.tool === 'recsFill' && (
                    <Rect
                      x={Math.min(
                        line.startPointX,
                        line.startPointX + line.width
                      )}
                      y={Math.min(
                        line.startPointY,
                        line.startPointY + line.height
                      )}
                      width={Math.abs(line.width)}
                      height={Math.abs(line.height)}
                      fill={line.lineColor}
                      stroke={line.lineColor}
                      strokeWidth={line.lineStroke}
                    />
                  )}
                  {line.tool === 'circle' && (
                    <Circle
                      x={line.x}
                      y={line.y}
                      radius={line.radius}
                      fill={'rgb(0,0,0,0)'}
                      stroke={line.lineColor}
                      strokeWidth={line.lineStroke}
                    />
                  )}
                  {line.tool === 'circlesFill' && (
                    <Circle
                      x={line.x}
                      y={line.y}
                      radius={line.radius}
                      fill={line.lineColor}
                      stroke={line.lineColor}
                      strokeWidth={line.lineStroke}
                    />
                  )}
                  {line.tool === 'text' && (
                    <Text
                      x={line.x}
                      y={line.y}
                      text={line.text}
                      stroke={line.textColor}
                      fontSize={line.fontSize}
                      fill={line.textColor}
                    />
                  )}
                </Fragment>
              ))}
          </Layer>
        </Stage>
        <SaveArea>
          {typeUser === 'profissional' && (
            <SecondaryButton onClick={() => setIsOpenAlert(true)}>
              Instruções
            </SecondaryButton>
          )}
          {typeUser === 'profissional' && (
            <SecondaryButton onClick={salvarFim}>Salvar</SecondaryButton>
          )}
          {typeUser === 'profissional' && (
            <SecondaryButton onClick={() => setModalCloseIsOpen(true)}>
              Fechar
            </SecondaryButton>
          )}
        </SaveArea>
      </StageBox>

      <ModalAddOps
        generatePdf={handleExport}
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        token={token}
      />
      <ModalAlert
        modalIsOpen={modalIsOpenAlert}
        setIsOpen={setIsOpenAlert}
        token={token}
        props={props}
        title={'Quadro de desenhos'}
        recurso={nome_recurso_cod}
        bt01Txt={'Fechar'}
      />
      <ModalAlert
        modalIsOpen={modalIsOpenAlertText}
        setIsOpen={setIsOpenAlertText}
        token={token}
        props={props}
        title={'Caixa de texto'}
        recurso={'quadro-de-desenhos-texto'}
        bt01Txt={'Fechar'}
      />
      <ModalConfirm
        title="Limpar o jogo"
        description="Deseja realmente limpar o jogo?"
        modalIsOpen={modalCleanIsOpen}
        setIsOpen={setModalCleanIsOpen}
        confirmButtonText="Limpar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCleanIsOpen(false)}
        onConfirm={clearScreen}
      />
      <ModalConfirm
        title="Fechar o jogo"
        description="Deseja realmente fechar o jogo?"
        modalIsOpen={modalCloseIsOpen}
        setIsOpen={setModalCloseIsOpen}
        confirmButtonText="Fechar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCloseIsOpen(false)}
        onConfirm={() =>
          handleBackHome(
            modalClose,
            nome_recurso_cod,
            typeUser,
            props,
            os,
            token,
            ambiente
          )
        }
      />
    </Container>
  );
}

export default Draw;
