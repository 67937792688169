import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: #f5f5f5;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const BoardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 10px;
  background: white;
  border-width: 20px;
  border-style: solid;
  border-image-source: linear-gradient(to bottom, #b1dffc, #775aa9);
  border-image-slice: 5;
  position: relative;

  @media (max-width: 786px) {
    width: 280px;
  }

  img {
    width: 130px;
    position: absolute;
    top: -57px;
  }

  h1 {
    font-size: 34px;
    font-weight: 400;
    font-family: 'Lobster', cursive;
    color: #263238;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .author {
    position: absolute;
    background: transparent;
    top: 441px;
    left: 299px;
    font-size: 13px;
    font-weight: 400;
    color: white;

    @media (max-width: 786px) {
      left: 182px;
    }
  }
`;

export const NumberContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 360px;
  height: 350px;
  box-shadow: 0px 10px 0px 0px #654d90;
  padding: 16px;

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin: 0;
    padding: 2px;
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    font-family: 'Lobster', cursive;
    font-size: 20px;
    font-weight: 400;
    color: #263238;

    @media (max-width: 786px) {
      width: 21.25px;
      height: 21.25px;
    }

    ::selection {
      border: 1px solid black;
    }
  }
  @media (max-width: 786px) {
    padding: 16px 0;
    width: 280px;
  }
`;

export const SaveArea = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 0 0 8px;
  margin-top: -20px;
  margin-bottom: 30px;

  @media (max-width: 786px) {
    flex-wrap: wrap;
  }
`;
