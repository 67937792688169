import { useState, useEffect, useRef } from 'react';
import domtoimage from 'dom-to-image';

import {
  modalOpen,
  modalClose,
  handleBackHome,
  sendArquivo,
  startSocket,
  downloadURI,
  DataURL,
} from '../../utils';

import {
  ModalAddOps,
  ModalAlert,
  SecondaryButton,
  PrimaryButton,
  ModalConfirm,
} from '../../components';

import { Board } from '@assets/';

import { Container, NumberContainer, SaveArea, BoardContainer } from './styles';

function FindTheTen(props) {
  const {
    nome_recurso_cod,
    typeUser,
    os,
    token,
    tokenSocketIo,
    ambiente,
  } = DataURL(props);

  // modal variables
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenAlert, setIsOpenAlert] = useState(false);
  const [modalCleanIsOpen, setModalCleanIsOpen] = useState(false);
  const [modalCloseIsOpen, setModalCloseIsOpen] = useState(false);

  // resource variables
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [randomNumbers, setRandomNumbers] = useState(genarateRandomNumbers());
  const [randomColor, setRandomColor] = useState('#b3dce8');

  // socket variables
  const [yourID, setYourID] = useState();
  const socketRef = useRef();

  // resource functions

  // random generators functions
  function generateRandomColor() {
    const randomColor = `hsla(${Math.random() * 360}, 100%, 50%, 1)`;

    return randomColor;
  }

  function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
  }

  function genarateRandomNumbers() {
    let array = [];
    while (array.length < 100) {
      const random = Math.floor(getRandomArbitrary(1, 10));
      if (random !== array[array.length - 1]) array.push(random);
    }
    return array;
  }

  // dom handlers

  function checkIfItsTen(index, numbers, ids, newRandomColor) {
    const sum = numbers.reduce((accumulator, a) => {
      return accumulator + a;
    }, 0);

    if (sum === 10) {
      ids.forEach((id) => {
        const number = document.getElementById(id);
        number.style.backgroundColor = newRandomColor;
        number.style.border = '1px solid #b3dce8';
      });

      setSelectedNumbers([]);
      setSelectedIds([]);
    } else {
      const lastIndex = document.getElementById(`${index}`);
      lastIndex.style.border = '1px solid #2797BA';
    }
  }

  function changeBorderUndo(index, newIds, newNumbers) {
    const lastSelectedNumber = document.getElementById(index);

    newNumbers.pop();
    newIds.pop();
    setSelectedNumbers(newNumbers);
    setSelectedIds(newIds);

    lastSelectedNumber.style.border = '1px solid transparent';
  }

  function changeStyleClean() {
    for (let i = 0; i < 100; i++) {
      const number = document.getElementById(`${i}`);
      number.style.backgroundColor = 'transparent';
      number.style.border = '1px solid transparent';
    }
  }

  // event handlers

  function handleOnClickNumber(event, index) {
    const newNumbers = [...selectedNumbers];
    const newIds = [...selectedIds];
    const newRandomColor = generateRandomColor();

    newNumbers.push(Number(event.target.innerHTML));
    newIds.push(index.toString());

    setSelectedNumbers(newNumbers);
    setSelectedIds(newIds);
    setRandomColor(newRandomColor);

    checkIfItsTen(index, newNumbers, newIds, newRandomColor);
    sendNumbers(
      randomNumbers,
      newNumbers,
      newIds,
      index,
      newRandomColor,
      'add'
    );
  }

  function handleUndoButton() {
    if (selectedIds.length > 0) {
      const newNumbers = [...selectedNumbers];
      const newIds = [...selectedIds];
      const newIndex = selectedIds[selectedIds.length - 1];

      changeBorderUndo(newIndex, newIds, newNumbers);

      sendNumbers(
        randomNumbers,
        newNumbers,
        newIds,
        newIndex,
        randomColor,
        'undo'
      );
    }
  }

  function handleClean() {
    const newNumbers = [];
    const newIds = [];
    const newRandomNumbers = genarateRandomNumbers();

    setSelectedNumbers(newNumbers);
    setSelectedIds(newIds);
    setRandomNumbers(newRandomNumbers);
    setModalCleanIsOpen(false);

    changeStyleClean();

    sendNumbers(
      newRandomNumbers,
      newNumbers,
      newIds,
      0,
      randomColor,
      'clear',
      false
    );
  }

  // modal function

  function generatePdf() {
    domtoimage
      .toPng(document.getElementById('divIdToPrint'))
      .then(function (dataUrl) {
        const nome_paciente = localStorage
          .getItem('@nome_paciente')
          .replaceAll(' ', '_');
        const filename = `TerapiaInterativa-${nome_recurso_cod}-${nome_paciente}.png`;
        downloadURI(
          dataUrl,
          filename,
          sendArquivo,
          os,
          token,
          nome_recurso_cod
        );
        setIsOpen(false);
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  }

  // socket functions

  function sendNumbers(
    randomNumbers,
    newNumbers,
    newIds,
    index,
    newRandomColor,
    typeOfMessage,
    shouldCheckTen = true
  ) {
    const messageObject = {
      body: {
        randomNumbers,
        newNumbers,
        newIds,
        index,
        newRandomColor,
        typeOfMessage,
        shouldCheckTen,
      },
      id: yourID,
      typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  function receivedMessage(message) {
    const newRandomNumbers = message.body.randomNumbers;
    const newNumbers = message.body.newNumbers;
    const newIds = message.body.newIds;
    const randomColor = message.body.newRandomColor;
    const index = message.body.index;
    const shouldCheckTen = message.body.shouldCheckTen;

    setSelectedNumbers(newNumbers);
    setSelectedIds(newIds);
    setRandomColor(randomColor);
    setRandomNumbers(newRandomNumbers);

    if (shouldCheckTen && message.body.typeOfMessage === 'add') {
      checkIfItsTen(index, newNumbers, newIds, randomColor);
    } else if (message.body.typeOfMessage === 'undo') {
      changeBorderUndo(index, newIds, newNumbers);
    } else {
      changeStyleClean();
    }
  }

  useEffect(() => {
    startSocket(socketRef, setYourID, receivedMessage, tokenSocketIo, typeUser);
    sendNumbers(
      randomNumbers,
      selectedNumbers,
      selectedIds,
      0,
      randomColor,
      'add',
      false
    );
    if (typeUser === 'profissional') {
      modalOpen(nome_recurso_cod, tokenSocketIo);
    }
  }, []);

  return (
    <Container>
      <SaveArea>
        {typeUser === 'profissional' && (
          <SecondaryButton mobile onClick={() => setIsOpenAlert(true)}>
            Instruções
          </SecondaryButton>
        )}
        {typeUser === 'profissional' && (
          <SecondaryButton mobile onClick={() => setIsOpen(true)}>
            Salvar
          </SecondaryButton>
        )}
        {typeUser === 'profissional' && (
          <SecondaryButton mobile onClick={() => setModalCloseIsOpen(true)}>
            Fechar
          </SecondaryButton>
        )}
        <PrimaryButton mobile onClick={handleUndoButton}>
          Desfazer
        </PrimaryButton>
        {typeUser === 'profissional' && (
          <PrimaryButton mobile onClick={() => setModalCleanIsOpen(true)}>
            Recomeçar
          </PrimaryButton>
        )}
      </SaveArea>
      <BoardContainer>
        <div
          style={{
            position: 'absolute',
            top: '-29px',
            width: '90px',
            height: '20px',
            background: '#b1defc',
            borderRadius: '20px',
          }}
        />
        <img src={Board} alt="Clipe de prancheta" />
        <h1>Ache o 10</h1>
        <NumberContainer id="divIdToPrint">
          {randomNumbers.map((number, index) => (
            <p id={index} onClick={(e) => handleOnClickNumber(e, index)}>
              {number}
            </p>
          ))}
        </NumberContainer>
        <p className="author">@psico.ednayara</p>
      </BoardContainer>

      <ModalAddOps
        generatePdf={generatePdf}
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        token={token}
      />
      <ModalAlert
        modalIsOpen={modalIsOpenAlert}
        setIsOpen={setIsOpenAlert}
        token={token}
        props={props}
        title={'Ache o dez'}
        recurso={nome_recurso_cod}
        bt01Txt={'Fechar'}
      />
      <ModalConfirm
        title="Limpar o jogo"
        description="Deseja realmente limpar o jogo?"
        modalIsOpen={modalCleanIsOpen}
        setIsOpen={setModalCleanIsOpen}
        confirmButtonText="Limpar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCleanIsOpen(false)}
        onConfirm={handleClean}
      />
      <ModalConfirm
        title="Fechar o jogo"
        description="Deseja realmente fechar o jogo?"
        modalIsOpen={modalCloseIsOpen}
        setIsOpen={setModalCloseIsOpen}
        confirmButtonText="Fechar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCloseIsOpen(false)}
        onConfirm={() =>
          handleBackHome(
            modalClose,
            nome_recurso_cod,
            typeUser,
            props,
            os,
            token,
            ambiente
          )
        }
      />
    </Container>
  );
}

export default FindTheTen;
