import { useState, useRef, useEffect } from 'react';
import domtoimage from 'dom-to-image';

import { IoMdClose as Close } from 'react-icons/io';
import { BiCircle as Circle } from 'react-icons/bi';

import {
  modalOpen,
  modalClose,
  handleBackHome,
  sendArquivo,
  startSocket,
  downloadURI,
  PacientName,
  DataURL,
} from '@utils';

import {
  ModalAddOps,
  ModalAlert,
  SecondaryButton,
  ModalConfirm,
  CircleOrX,
} from '@components';

import {
  Table,
  Container,
  ScoreContainer,
  TurnBox,
  TableButton,
  SaveArea,
  GlobalStyle,
  WaitToPlayBox,
} from './styles';

function JogoDaVelha(props) {
  const {
    nome_recurso_cod,
    typeUser,
    os,
    token,
    tokenSocketIo,
    ambiente,
  } = DataURL(props);

  //socket variables
  const [yourID, setYourID] = useState();
  const socketRef = useRef();

  //Modal variables
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenAlert, setIsOpenAlert] = useState(false);
  const [modalCleanIsOpen, setModalCleanIsOpen] = useState(false);
  const [modalCleanScoreIsOpen, setModalCleanScoreIsOpen] = useState(false);
  const [modalCloseIsOpen, setModalCloseIsOpen] = useState(false);

  const [currentWinner, setCurrentWinner] = useState('');

  const [score, setScore] = useState({
    circle: 0,
    close: 0,
  });
  const [currentIcon, setCurrentIcon] = useState('');
  const [shouldEnableGame, setShouldEnableGame] = useState(false);
  const [iconsDisplay, setIconsDisplay] = useState({
    icon1: '',
    icon2: '',
    icon3: '',
    icon4: '',
    icon5: '',
    icon6: '',
    icon7: '',
    icon8: '',
    icon9: '',
  });
  const [shouldShowIcon, setShouldShowIcon] = useState({
    icon1: false,
    icon2: false,
    icon3: false,
    icon4: false,
    icon5: false,
    icon6: false,
    icon7: false,
    icon8: false,
    icon9: false,
  });

  function checkIfHasWon(obj, player) {
    const hasCircleWonCase1 =
      obj.icon1 === player && obj.icon2 === player && obj.icon3 === player;

    const hasCircleWonCase2 =
      obj.icon4 === player && obj.icon5 === player && obj.icon6 === player;

    const hasCircleWonCase3 =
      obj.icon7 === player && obj.icon8 === player && obj.icon9 === player;

    const hasCircleWonCase4 =
      obj.icon1 === player && obj.icon5 === player && obj.icon9 === player;

    const hasCircleWonCase5 =
      obj.icon1 === player && obj.icon4 === player && obj.icon7 === player;

    const hasCircleWonCase6 =
      obj.icon2 === player && obj.icon5 === player && obj.icon8 === player;

    const hasCircleWonCase7 =
      obj.icon3 === player && obj.icon5 === player && obj.icon7 === player;

    const hasCircleWonCase8 =
      obj.icon3 === player && obj.icon6 === player && obj.icon9 === player;

    const hasWon =
      hasCircleWonCase1 ||
      hasCircleWonCase2 ||
      hasCircleWonCase3 ||
      hasCircleWonCase4 ||
      hasCircleWonCase5 ||
      hasCircleWonCase6 ||
      hasCircleWonCase7 ||
      hasCircleWonCase8;

    return hasWon;
  }

  function showIconOnClick(icon) {
    let newObjShowIcon = { ...shouldShowIcon };
    let newObjIconDisplay = { ...iconsDisplay };
    let newCurrentIcon = currentIcon;
    let newShouldEnable = true;

    newObjShowIcon[icon] = true;
    newObjIconDisplay[icon] = currentIcon;

    if (newCurrentIcon === 'circle') {
      newCurrentIcon = 'close';
    } else {
      newCurrentIcon = 'circle';
    }

    const hasCircleWon = checkIfHasWon(newObjIconDisplay, 'circle');
    const hasCloseWon = checkIfHasWon(newObjIconDisplay, 'close');

    const newScore = { ...score };

    let newCurrentWinner = currentWinner;

    if (hasCloseWon) {
      newCurrentWinner = 'close';
      newScore.close = newScore.close + 1;
    } else if (hasCircleWon) {
      newCurrentWinner = 'circle';
      newScore.circle = newScore.circle + 1;
    }

    if (hasCircleWon || hasCloseWon) {
      newObjIconDisplay = {
        icon1: '',
        icon2: '',
        icon3: '',
        icon4: '',
        icon5: '',
        icon6: '',
        icon7: '',
        icon8: '',
        icon9: '',
      };

      newObjShowIcon = {
        icon1: false,
        icon2: false,
        icon3: false,
        icon4: false,
        icon5: false,
        icon6: false,
        icon7: false,
        icon8: false,
        icon9: false,
      };

      newCurrentIcon = '';

      newShouldEnable = false;
    }

    let sum = 0;
    for (let prop in newObjIconDisplay) {
      if (newObjIconDisplay[prop] !== '') {
        sum++;
      }
    }

    if (sum === 9 && !hasCircleWon && !hasCloseWon) {
      newObjIconDisplay = {
        icon1: '',
        icon2: '',
        icon3: '',
        icon4: '',
        icon5: '',
        icon6: '',
        icon7: '',
        icon8: '',
        icon9: '',
      };

      newObjShowIcon = {
        icon1: false,
        icon2: false,
        icon3: false,
        icon4: false,
        icon5: false,
        icon6: false,
        icon7: false,
        icon8: false,
        icon9: false,
      };

      newCurrentWinner = 'empate';
      newCurrentIcon = '';
      newShouldEnable = false;
    }

    setShouldShowIcon(newObjShowIcon);
    setIconsDisplay(newObjIconDisplay);
    setCurrentIcon(newCurrentIcon);
    setShouldEnableGame(newShouldEnable);
    setScore(newScore);
    setCurrentWinner(newCurrentWinner);

    sendInformation(
      newObjShowIcon,
      newObjIconDisplay,
      newScore,
      newCurrentIcon,
      newCurrentWinner,
      newShouldEnable
    );
  }

  function handleClean() {
    const newObjIconDisplay = {
      icon1: '',
      icon2: '',
      icon3: '',
      icon4: '',
      icon5: '',
      icon6: '',
      icon7: '',
      icon8: '',
      icon9: '',
    };
    const newObjShowIcon = {
      icon1: false,
      icon2: false,
      icon3: false,
      icon4: false,
      icon5: false,
      icon6: false,
      icon7: false,
      icon8: false,
      icon9: false,
    };

    const newCurrentIcon = '';
    const newCurrentWinner = '';
    const newShouldEnable = false;

    sendInformation(
      newObjShowIcon,
      newObjIconDisplay,
      score,
      newCurrentIcon,
      newCurrentWinner,
      newShouldEnable
    );

    setIconsDisplay(newObjIconDisplay);
    setShouldShowIcon(newObjShowIcon);
    setCurrentWinner(newCurrentWinner);
    setCurrentIcon(newCurrentIcon);
    setShouldEnableGame(newShouldEnable);
    setModalCleanIsOpen(false);
  }

  function handleCleanScore() {
    const newScore = {
      circle: 0,
      close: 0,
    };

    const newCurrentWinner = '';
    const newShouldEnable = false;

    setScore(newScore);
    setCurrentWinner(newCurrentWinner);

    sendInformation(
      shouldShowIcon,
      iconsDisplay,
      newScore,
      currentIcon,
      newCurrentWinner,
      newShouldEnable
    );
    setModalCleanScoreIsOpen(false);
  }

  // socket related functions
  function sendInformation(
    newObjShowIcon,
    newObjIconDisplay,
    newScore,
    newCurrentIcon,
    newCurrentWinner,
    newShouldEnable
  ) {
    const messageObject = {
      body: {
        newObjShowIcon,
        newObjIconDisplay,
        newScore,
        newCurrentIcon,
        newCurrentWinner,
        newShouldEnable,
      },
      id: yourID,
      typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  function receivedMessage(message) {
    const newCurrentIcon = message.body.newCurrentIcon;
    const newObjShowIcon = message.body.newObjShowIcon;
    const newScore = message.body.newScore;
    const newObjIconDisplay = message.body.newObjIconDisplay;
    const newCurrentWinner = message.body.newCurrentWinner;
    const newShouldEnable = message.body.newShouldEnable;

    setShouldShowIcon(newObjShowIcon);
    setIconsDisplay(newObjIconDisplay);
    setScore(newScore);
    setCurrentIcon(newCurrentIcon);
    setCurrentWinner(newCurrentWinner);

    setShouldEnableGame(newShouldEnable);
  }

  useEffect(() => {
    startSocket(socketRef, setYourID, receivedMessage, tokenSocketIo, typeUser);
    if (typeUser === 'profissional') {
      modalOpen(nome_recurso_cod, tokenSocketIo);
    }
  }, []);

  // modal function
  function generatePdf() {
    domtoimage
      .toPng(document.getElementById('divIdToPrint'))
      .then(function (dataUrl) {
        const nome_paciente = localStorage
          .getItem('@nome_paciente')
          .replaceAll(' ', '_');
        const filename = `TerapiaInterativa-${nome_recurso_cod}-${nome_paciente}.png`;
        downloadURI(
          dataUrl,
          filename,
          sendArquivo,
          os,
          token,
          nome_recurso_cod
        );

        setIsOpen(false);
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  }

  return (
    <Container>
      <GlobalStyle />
      <SaveArea>
        {typeUser === 'profissional' && (
          <SecondaryButton mobile onClick={() => setIsOpenAlert(true)}>
            Instruções
          </SecondaryButton>
        )}
        {typeUser === 'profissional' && (
          <SecondaryButton mobile onClick={() => setIsOpen(true)}>
            Salvar
          </SecondaryButton>
        )}
        {typeUser === 'profissional' && (
          <SecondaryButton mobile onClick={() => setModalCloseIsOpen(true)}>
            Fechar
          </SecondaryButton>
        )}
        {typeUser === 'profissional' && (
          <SecondaryButton mobile onClick={() => setModalCleanIsOpen(true)}>
            Limpar
          </SecondaryButton>
        )}
        {typeUser === 'profissional' && (
          <SecondaryButton
            mobile
            onClick={() => setModalCleanScoreIsOpen(true)}
            style={{ maxWidth: '128px' }}
          >
            Limpar Placar
          </SecondaryButton>
        )}
      </SaveArea>
      <div
        id="divIdToPrint"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ScoreContainer icon={currentIcon}>
          <div
            onClick={() => {
              const newShouldEnable = true;

              setCurrentIcon('close');
              setShouldEnableGame(newShouldEnable);
              sendInformation(
                shouldShowIcon,
                iconsDisplay,
                score,
                currentIcon,
                currentWinner,
                newShouldEnable
              );
            }}
            style={{
              boxShadow:
                currentIcon === 'close'
                  ? '0 4px 4px 0 rgba(0, 0, 0, 0.25)'
                  : null,
              border: currentIcon === 'close' ? '1px solid #2797ba' : null,
            }}
          >
            <Close
              style={{
                color: '#F44336',
                fontSize: '30px',
                strokeWidth: '20px',
                marginLeft: '8px',
              }}
            />
            <h2>{score.close}</h2>
          </div>
          <div
            onClick={() => {
              const newShouldEnable = true;
              setCurrentIcon('circle');
              setShouldEnableGame(newShouldEnable);
              sendInformation(
                shouldShowIcon,
                iconsDisplay,
                score,
                currentIcon,
                currentWinner,
                newShouldEnable
              );
            }}
            style={{
              boxShadow:
                currentIcon === 'circle'
                  ? '0 4px 4px 0 rgba(0, 0, 0, 0.25)'
                  : null,
              border: currentIcon === 'circle' ? '1px solid #2797ba' : null,
            }}
          >
            <Circle
              style={{
                color: '#2797BA',
                fontSize: '29px',
                strokeWidth: '1px',
                marginLeft: '8px',
              }}
            />
            <h2>{score.circle}</h2>
          </div>
        </ScoreContainer>

        <TurnBox>
          <p>vez do jogador </p>
          {currentIcon === 'circle' ? (
            <Circle
              style={{
                color: '#2797BA',
                strokeWidth: '2px',
                marginLeft: '5px',
              }}
            />
          ) : (
            <Close
              style={{
                color: '#F44336',
                strokeWidth: '20px',
                marginLeft: '5px',
              }}
            />
          )}
        </TurnBox>

        {!shouldEnableGame && currentWinner === '' && (
          <WaitToPlayBox>
            <h2 style={{ fontSize: '28px' }}>
              Escolha um dos simbolos para jogar
            </h2>
          </WaitToPlayBox>
        )}
        {!shouldEnableGame && currentWinner === 'close' && (
          <WaitToPlayBox>
            <Close
              style={{
                color: '#F44336',
                strokeWidth: '20px',
                marginLeft: '5px',
                fontSize: '100px',
              }}
            />
            <h2>Vencedor</h2>
          </WaitToPlayBox>
        )}
        {!shouldEnableGame && currentWinner === 'circle' && (
          <WaitToPlayBox>
            <Circle
              style={{
                color: '#2797BA',
                strokeWidth: '2px',
                marginLeft: '5px',
                fontSize: '80px',
              }}
            />
            <h2>Vencedor</h2>
          </WaitToPlayBox>
        )}
        {!shouldEnableGame && currentWinner === 'empate' && (
          <WaitToPlayBox>
            <div>
              <Close
                style={{
                  color: '#F44336',
                  strokeWidth: '20px',
                  marginLeft: '5px',
                  fontSize: '100px',
                }}
              />
              <Circle
                style={{
                  color: '#2797BA',
                  strokeWidth: '2px',
                  marginLeft: '5px',
                  fontSize: '80px',
                }}
              />
            </div>
            <h2>Empate</h2>
          </WaitToPlayBox>
        )}

        {shouldEnableGame && (
          <Table>
            <TableButton
              className="remove-left-border remove-top-border"
              onClick={shouldEnableGame ? () => showIconOnClick('icon1') : null}
              cursor={shouldEnableGame}
            >
              <CircleOrX
                firstCondition={shouldShowIcon.icon1}
                icon={iconsDisplay.icon1}
              />
            </TableButton>
            <TableButton
              className="remove-top-border"
              onClick={shouldEnableGame ? () => showIconOnClick('icon2') : null}
              cursor={shouldEnableGame}
            >
              <CircleOrX
                firstCondition={shouldShowIcon.icon2}
                icon={iconsDisplay.icon2}
              />
            </TableButton>
            <TableButton
              className="remove-right-border remove-top-border"
              onClick={shouldEnableGame ? () => showIconOnClick('icon3') : null}
              cursor={shouldEnableGame}
            >
              <CircleOrX
                firstCondition={shouldShowIcon.icon3}
                icon={iconsDisplay.icon3}
              />
            </TableButton>
            <TableButton
              className="remove-left-border"
              onClick={shouldEnableGame ? () => showIconOnClick('icon4') : null}
              cursor={shouldEnableGame}
            >
              <CircleOrX
                firstCondition={shouldShowIcon.icon4}
                icon={iconsDisplay.icon4}
              />
            </TableButton>
            <TableButton
              onClick={shouldEnableGame ? () => showIconOnClick('icon5') : null}
              cursor={shouldEnableGame}
            >
              <CircleOrX
                firstCondition={shouldShowIcon.icon5}
                icon={iconsDisplay.icon5}
              />
            </TableButton>
            <TableButton
              className="remove-right-border"
              onClick={shouldEnableGame ? () => showIconOnClick('icon6') : null}
              cursor={shouldEnableGame}
            >
              <CircleOrX
                firstCondition={shouldShowIcon.icon6}
                icon={iconsDisplay.icon6}
              />
            </TableButton>
            <TableButton
              className="remove-left-border remove-bottom-border"
              onClick={shouldEnableGame ? () => showIconOnClick('icon7') : null}
              cursor={shouldEnableGame}
            >
              <CircleOrX
                firstCondition={shouldShowIcon.icon7}
                icon={iconsDisplay.icon7}
              />
            </TableButton>
            <TableButton
              className="remove-bottom-border"
              onClick={shouldEnableGame ? () => showIconOnClick('icon8') : null}
              cursor={shouldEnableGame}
            >
              <CircleOrX
                firstCondition={shouldShowIcon.icon8}
                icon={iconsDisplay.icon8}
              />
            </TableButton>
            <TableButton
              className="remove-right-border remove-bottom-border"
              onClick={shouldEnableGame ? () => showIconOnClick('icon9') : null}
              cursor={shouldEnableGame}
            >
              <CircleOrX
                firstCondition={shouldShowIcon.icon9}
                icon={iconsDisplay.icon9}
              />
            </TableButton>
          </Table>
        )}
      </div>

      <ModalAddOps
        generatePdf={generatePdf}
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        token={token}
      />
      <ModalAlert
        modalIsOpen={modalIsOpenAlert}
        setIsOpen={setIsOpenAlert}
        token={token}
        props={props}
        title={'Jogo da Velha'}
        recurso={nome_recurso_cod}
        bt01Txt={'Fechar'}
      />
      <ModalConfirm
        title="Limpar o jogo"
        description="Deseja realmente limpar o jogo?"
        modalIsOpen={modalCleanIsOpen}
        setIsOpen={setModalCleanIsOpen}
        confirmButtonText="Limpar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCleanIsOpen(false)}
        onConfirm={handleClean}
      />
      <ModalConfirm
        title="Limpar o Placar"
        description="Deseja realmente limpar o Placar?"
        modalIsOpen={modalCleanScoreIsOpen}
        setIsOpen={setModalCleanScoreIsOpen}
        confirmButtonText="Limpar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCleanScoreIsOpen(false)}
        onConfirm={handleCleanScore}
      />
      <ModalConfirm
        title="Fechar o jogo"
        description="Deseja realmente fechar o jogo?"
        modalIsOpen={modalCloseIsOpen}
        setIsOpen={setModalCloseIsOpen}
        confirmButtonText="Fechar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCloseIsOpen(false)}
        onConfirm={() =>
          handleBackHome(
            modalClose,
            nome_recurso_cod,
            typeUser,
            props,
            os,
            token,
            ambiente
          )
        }
      />
    </Container>
  );
}

export default JogoDaVelha;
