import { useState, useEffect, useRef } from 'react';

import domtoimage from 'dom-to-image';

import {
  modalOpen,
  modalClose,
  handleBackHome,
  sendArquivo,
  startSocket,
  downloadURI,
  DataURL,
} from '../../utils';

import {
  ModalAddOps,
  ModalAlert,
  SecondaryButton,
  TertiaryButton,
  ModalConfirm,
} from '../../components';

import { FirstPageDraw } from '@assets';

import {
  Container,
  FirstPageContainer,
  FirstPageTitleWrapper,
  PageContainer,
  PlayGameWrapper,
  GameButtonPrimary,
  GameButtonSecondary,
  Score,
  ScoreWrapper,
  ScoreRow,
  SaveArea,
  QuestionBoxWrapper,
  QuestionBox,
  LetterDisplay,
  StopButtonContainer,
  AnimationText,
  AnsewerBox,
  FinishButtonContainer,
  NextButton,
  Timer,
} from './styles';

function Stop(props) {
  const { nome_recurso_cod, typeUser, os, token, tokenSocketIo, ambiente } = DataURL(
    props
  );

  //socket variables
  const [yourID, setYourID] = useState();
  const socketRef = useRef();

  //Modal variables
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenAlert, setIsOpenAlert] = useState(false);
  const [modalCleanIsOpen, setModalCleanIsOpen] = useState(false);
  const [modalCloseIsOpen, setModalCloseIsOpen] = useState(false);

  //game variables
  const [page, setPage] = useState(1);

  const [scorePlayerOne, setScorePlayerOne] = useState([
    { round: 'Rodada 1', points: '' },
    { round: 'Rodada 2', points: '' },
    { round: 'Rodada 3', points: '' },
    { round: 'Rodada 4', points: '' },
    { round: 'Rodada 5', points: '' },
    { round: 'Total', points: '' },
  ]);
  const [scorePlayerTwo, setScorePlayerTwo] = useState([
    { round: 'Rodada 1', points: '' },
    { round: 'Rodada 2', points: '' },
    { round: 'Rodada 3', points: '' },
    { round: 'Rodada 4', points: '' },
    { round: 'Rodada 5', points: '' },
    { round: 'Total', points: '' },
  ]);
  const [categoriesPlayerOne, setCategoriesPlayerOne] = useState([
    { categorie: 'NOME', answer: '' },
    { categorie: 'ANIMAL', answer: '' },
    { categorie: 'OBJETO', answer: '' },
    { categorie: 'FRUTA, VERDURA OU LEGUME', answer: '' },
    { categorie: 'COR', answer: '' },
    { categorie: 'CIDADE, ESTADO OU PAÍS', answer: '' },
    { categorie: 'FILME OU TV', answer: '' },
    { categorie: 'CARRO', answer: '' },
    { categorie: 'PESSOA FAMOSA', answer: '' },
    { categorie: 'EU SOU...', answer: '' },
  ]);
  const [categoriesPlayerTwo, setCategoriesPlayerTwo] = useState([
    { categorie: 'NOME', answer: '' },
    { categorie: 'ANIMAL', answer: '' },
    { categorie: 'OBJETO', answer: '' },
    { categorie: 'FRUTA, VERDURA OU LEGUME', answer: '' },
    { categorie: 'COR', answer: '' },
    { categorie: 'CIDADE, ESTADO OU PAÍS', answer: '' },
    { categorie: 'FILME OU TV', answer: '' },
    { categorie: 'CARRO', answer: '' },
    { categorie: 'PESSOA FAMOSA', answer: '' },
    { categorie: 'EU SOU...', answer: '' },
  ]);

  const [poinsInputPlayerOne, setPointsInputPlayerOne] = useState([
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ]);
  const [poinsInputPlayerTwo, setPointsInputPlayerTwo] = useState([
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ]);
  const [alphabet, setAlphabet] = useState([
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'X',
    'Z',
  ]);

  const [sortedLetter, setSortedLetter] = useState('');
  const [round, setRound] = useState(0);
  const [shouldShowStopAnimation, setShouldShowStopAnimation] = useState(false);
  const [shouldEnableNextRound, setShouldEnableNextRound] = useState(false);
  const [seconds, setSeconds] = useState(null);

  //game functions

  function setPageOnClick(numberOfPage) {
    const newPage = numberOfPage;
    setPage(newPage);
    sendInformation(newPage, 'updatePage');
  }

  function handleRandomString() {
    const newAlphabet = [...alphabet];

    const randomCharacter = newAlphabet[Math.floor(Math.random() * newAlphabet.length)];

    for (var i = 0; i < newAlphabet.length; i++) {
      if (newAlphabet[i] === randomCharacter) {
        newAlphabet.splice(i, 1);
      }
    }

    setAlphabet(newAlphabet);
    setSortedLetter(randomCharacter);

    sendInformation([newAlphabet, randomCharacter], 'updateLetter');
  }

  function handleScoreOnChange(event, index) {
    const newValue = event.target.value;
    const newScore =
      typeUser === 'profissional' ? [...scorePlayerOne] : [...scorePlayerTwo];

    newScore[index].points = newValue;

    if (typeUser === 'profissional') {
      setScorePlayerOne(newScore);
    } else {
      setScorePlayerTwo(newScore);
    }
    sendInformation(newScore, 'updateScore');
  }

  function handleCategoriesOnChange(event, index) {
    const newValue = event.target.value;
    const newPlayerCategories =
      typeUser === 'profissional' ? [...categoriesPlayerOne] : [...categoriesPlayerTwo];

    newPlayerCategories[index].answer = newValue;

    if (typeUser === 'profissional') {
      setCategoriesPlayerOne(newPlayerCategories);
    } else {
      setCategoriesPlayerTwo(newPlayerCategories);
    }

    sendInformation(newPlayerCategories, 'updateCategories');
  }

  function handleStopOnClick() {
    setShouldShowStopAnimation(true);

    setTimeout(() => {
      setShouldShowStopAnimation(false);
      setPage(5);
    }, 1000);

    sendInformation(5, 'updatePageStop');
  }

  function handlePointsOnChange(event, index) {
    const newValue = event.target.value;
    const newPoints =
      typeUser === 'profissional' ? [...poinsInputPlayerOne] : [...poinsInputPlayerTwo];
    const newScore =
      typeUser === 'profissional' ? [...scorePlayerOne] : [...scorePlayerTwo];

    newPoints[index] = Number(newValue);

    if (typeUser === 'profissional') {
      setPointsInputPlayerOne(newPoints);
    } else {
      setPointsInputPlayerTwo(newPoints);
    }

    let sumOfPoints = 0;

    for (var i = 0; i < newPoints.length; i++) {
      sumOfPoints += newPoints[i];
    }

    newScore[round].points = sumOfPoints;

    if (typeUser === 'profissional') {
      setScorePlayerOne(newScore);
    } else {
      setScorePlayerTwo(newScore);
    }

    sendInformation([newPoints, newScore], 'updatePoints');

    if (index === 9) {
      setShouldEnableNextRound(true);
      sendInformation(true, 'updateEnableNextRound');
    }
  }

  function handleNextRoundOnClick() {
    if (round === 4) {
      const newScoreProfessional = [...scorePlayerOne];
      const newScorePatient = [...scorePlayerTwo];

      let sumOfTotalProfessional = 0;
      let sumOfTotalPatient = 0;

      for (var i = 0; i < newScoreProfessional.length - 1; i++) {
        sumOfTotalProfessional += newScoreProfessional[i].points;
        sumOfTotalPatient += newScorePatient[i].points;
      }

      newScoreProfessional[5].points = sumOfTotalProfessional;
      newScorePatient[5].points = sumOfTotalPatient;

      setScorePlayerOne(newScoreProfessional);
      setScorePlayerTwo(newScorePatient);
      setPage(6);

      sendInformation([6, newScoreProfessional, newScorePatient], 'resultsPage');
    } else {
      const newRound = round + 1;
      const newPage = 2;
      const newCategories = [...categoriesPlayerOne];
      const newPoints = [...poinsInputPlayerOne];

      for (let i = 0; i < newCategories.length; i++) {
        newCategories[i].answer = '';
      }

      for (let i = 0; i < newPoints.length; i++) {
        newPoints[i] = 0;
      }

      setPage(newPage);
      setRound(newRound);
      setShouldEnableNextRound(false);
      setCategoriesPlayerOne(newCategories);
      setPointsInputPlayerOne(newPoints);
      setCategoriesPlayerTwo(newCategories);
      setPointsInputPlayerTwo(newPoints);

      sendInformation([newPage, newRound, newCategories, newPoints], 'updateRound');
    }
  }

  function handleRestart() {
    const newPage = 1;
    const newScore =
      typeUser === 'profissional' ? [...scorePlayerOne] : [...scorePlayerTwo];
    const newCategories =
      typeUser === 'profissional' ? [...categoriesPlayerOne] : [...categoriesPlayerTwo];
    const newPoints =
      typeUser === 'profissional' ? [...poinsInputPlayerOne] : [...poinsInputPlayerTwo];

    for (let i = 0; i < newScore.length; i++) {
      newScore[i].points = 0;
    }

    for (let i = 0; i < newCategories.length; i++) {
      newCategories[i].answer = '';
    }

    for (let i = 0; i < newPoints.length; i++) {
      newPoints[i] = 0;
    }

    setScorePlayerOne(newScore);
    setCategoriesPlayerOne(newCategories);
    setPointsInputPlayerOne(newPoints);
    setScorePlayerTwo(newScore);
    setCategoriesPlayerTwo(newCategories);
    setPointsInputPlayerTwo(newPoints);
    setPage(newPage);
    setRound(0);
    setShouldEnableNextRound(false);
    setModalCleanIsOpen(false);
    sendInformation([newPage, newScore, newCategories, newPoints], 'restart');
  }

  function initiateTimer() {
    const totalSeconds = 20;

    setSeconds(totalSeconds);

    sendInformation(totalSeconds, 'updateTimer');
  }

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => {
        setSeconds(seconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      setShouldShowStopAnimation(true);

      setTimeout(() => {
        setShouldShowStopAnimation(false);
        setPage(5);
      }, 1000);
    }
  });

  // socket related functions
  function sendInformation(information, messageType) {
    const messageObject = {
      body: {
        information,
        messageType,
      },
      id: yourID,
      typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  function receivedMessage(message) {
    const messageType = message.body.messageType;
    const recievedInformation = message.body.information;
    const isMessageFromPatient = message.typeUser === 'paciente';

    switch (messageType) {
      case 'updatePage':
        setPage(recievedInformation);
        break;
      case 'updateLetter':
        setAlphabet(recievedInformation[0]);
        setSortedLetter(recievedInformation[1]);
        break;
      case 'updatePageStop':
        setShouldShowStopAnimation(true);
        setTimeout(() => {
          setShouldShowStopAnimation(false);
          setPage(recievedInformation);
        }, 1000);
        break;
      case 'updateCategories':
        if (isMessageFromPatient) {
          setCategoriesPlayerTwo(recievedInformation);
        } else {
          setCategoriesPlayerOne(recievedInformation);
        }
        break;
      case 'updateScore':
        if (isMessageFromPatient) {
          setScorePlayerTwo(recievedInformation);
        } else {
          setScorePlayerOne(recievedInformation);
        }
        break;
      case 'updatePoints':
        if (isMessageFromPatient) {
          setPointsInputPlayerTwo(recievedInformation[0]);
          setScorePlayerTwo(recievedInformation[1]);
        } else {
          setPointsInputPlayerOne(recievedInformation[0]);
          setScorePlayerOne(recievedInformation[1]);
        }
        break;
      case 'updateEnableNextRound':
        setShouldEnableNextRound(recievedInformation);
        break;
      case 'resultsPage':
        setPage(recievedInformation[0]);
        setScorePlayerOne(recievedInformation[1]);
        setScorePlayerTwo(recievedInformation[2]);
        break;
      case 'updateRound':
        setPage(recievedInformation[0]);
        setRound(recievedInformation[1]);
        setShouldEnableNextRound(false);
        setCategoriesPlayerTwo(recievedInformation[2]);
        setPointsInputPlayerTwo(recievedInformation[3]);
        setCategoriesPlayerOne(recievedInformation[2]);
        setPointsInputPlayerOne(recievedInformation[3]);
        break;
      case 'updateTimer':
        setSeconds(recievedInformation);
        break;
      case 'restart':
        setPage(recievedInformation[0]);
        setScorePlayerTwo(recievedInformation[1]);
        setCategoriesPlayerTwo(recievedInformation[2]);
        setPointsInputPlayerTwo(recievedInformation[3]);
        setScorePlayerOne(recievedInformation[1]);
        setCategoriesPlayerOne(recievedInformation[2]);
        setPointsInputPlayerOne(recievedInformation[3]);
        setRound(0);
        setShouldEnableNextRound(false);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    startSocket(socketRef, setYourID, receivedMessage, tokenSocketIo, typeUser);
    if (typeUser === 'profissional') {
      modalOpen(nome_recurso_cod, tokenSocketIo);
    }
  }, []);

  //Modal function
  function salvarFim() {
    setIsOpen(true);
  }

  function handleExport() {
    domtoimage
      .toPng(document.getElementById('divIdToPrint'))
      .then(function (dataUrl) {
        const nome_paciente = localStorage.getItem('@nome_paciente').replaceAll(' ', '_');
        const filename = `TerapiaInterativa-${nome_recurso_cod}-${nome_paciente}.png`;
        downloadURI(dataUrl, filename, sendArquivo, os, token, nome_recurso_cod);
        setIsOpen(false);
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  }

  return (
    <Container>
      <SaveArea>
        {typeUser === 'profissional' && (
          <SecondaryButton mobile onClick={() => setIsOpenAlert(true)}>
            Instruções
          </SecondaryButton>
        )}
        {typeUser === 'profissional' && (
          <SecondaryButton mobile onClick={() => setModalCloseIsOpen(true)}>
            Fechar
          </SecondaryButton>
        )}
        {typeUser === 'profissional' && (
          <SecondaryButton mobile onClick={salvarFim}>
            Salvar
          </SecondaryButton>
        )}
        {typeUser === 'profissional' && (
          <TertiaryButton mobile onClick={() => setModalCleanIsOpen(true)}>
            Reiniciar
          </TertiaryButton>
        )}
      </SaveArea>

      <PageContainer>
        {page === 1 && (
          <FirstPageContainer>
            <FirstPageTitleWrapper>
              <h1>
                Stop <br />
                (adedonha)
              </h1>
              <GameButtonPrimary onClick={() => setPageOnClick(2)}>
                Jogar
              </GameButtonPrimary>
            </FirstPageTitleWrapper>
            <img src={FirstPageDraw} alt="homem flutuando" />
          </FirstPageContainer>
        )}
        {(page === 2 || page === 3) && (
          <PlayGameWrapper>
            {page === 2 && (
              <>
                <h2>
                  Para iniciar o jogo, <br />
                  clique no botão para <br />
                  sortear a letra
                </h2>
                <GameButtonPrimary
                  width="190px"
                  onClick={() => {
                    setPageOnClick(3);
                    handleRandomString();
                  }}
                >
                  Sortear Letra
                </GameButtonPrimary>
              </>
            )}
            {page === 3 && (
              <>
                <h2>Letra sorteada:</h2>
                <h1>{sortedLetter}</h1>
                <div>
                  <GameButtonSecondary onClick={() => setPageOnClick(2)}>
                    voltar
                  </GameButtonSecondary>
                  <GameButtonPrimary
                    onClick={() => {
                      setPageOnClick(4);
                      initiateTimer();
                    }}
                  >
                    Jogar
                  </GameButtonPrimary>
                </div>
              </>
            )}
          </PlayGameWrapper>
        )}
        {(page === 4 || page === 5 || page === 6) && (
          <ScoreWrapper page={page}>
            {(page === 4 || page === 5) && (
              <LetterDisplay>
                <span>
                  <h1>Letra:</h1>
                  <h2>{sortedLetter}</h2>
                </span>
                <h3>{typeUser === 'profissional' ? 'PL 1' : 'PL 2'}</h3>
              </LetterDisplay>
            )}
            <Score>
              <ScoreRow>
                <h1>PL 1</h1>
                {scorePlayerOne.map((obj, index) => (
                  <div key={index}>
                    <h2>{obj.round}</h2>
                    <input
                      type="text"
                      value={obj.points}
                      placeholder="00"
                      onChange={(event) => handleScoreOnChange(event, index)}
                    />
                  </div>
                ))}
              </ScoreRow>
              <ScoreRow>
                <h1>PL 2</h1>
                {scorePlayerTwo.map((obj, index) => (
                  <div key={index}>
                    <h2>{obj.round}</h2>
                    <input
                      type="text"
                      value={obj.points}
                      placeholder="00"
                      onChange={(event) => handleScoreOnChange(event, index)}
                    />
                  </div>
                ))}
              </ScoreRow>
            </Score>
          </ScoreWrapper>
        )}
        {page === 4 && (
          <>
            <QuestionBoxWrapper>
              {shouldShowStopAnimation && <AnimationText>STOP!</AnimationText>}
              {typeUser === 'profissional' &&
                categoriesPlayerOne.map((categorieObj, index) => (
                  <QuestionBox key={index}>
                    <span>{categorieObj.categorie}</span>
                    <input
                      type="text"
                      value={categorieObj.answer}
                      placeholder="Digite aqui"
                      onChange={(event) => handleCategoriesOnChange(event, index)}
                    />
                  </QuestionBox>
                ))}
              {typeUser === 'paciente' &&
                categoriesPlayerTwo.map((categorieObj, index) => (
                  <QuestionBox key={index}>
                    <span>{categorieObj.categorie}</span>
                    <input
                      type="text"
                      value={categorieObj.answer}
                      placeholder="Digite aqui"
                      onChange={(event) => handleCategoriesOnChange(event, index)}
                    />
                  </QuestionBox>
                ))}
            </QuestionBoxWrapper>

            <StopButtonContainer>
              <Timer>
                <div>
                  <h1>{Math.floor(seconds / 60)}</h1>
                </div>
                <span>
                  <p>:</p>
                </span>
                <div>
                  <h1>
                    {seconds % 60 < 10 ? '0' : ''}
                    {seconds % 60}
                  </h1>
                </div>
              </Timer>
              <button onClick={handleStopOnClick}>STOP!</button>
            </StopButtonContainer>
          </>
        )}
        {page === 5 && (
          <>
            <QuestionBoxWrapper>
              {typeUser === 'profissional' &&
                categoriesPlayerOne.map((categorieObj, index) => (
                  <AnsewerBox key={index}>
                    <span>{categorieObj.categorie}</span>
                    <div className="playerOne">
                      <p>{categorieObj.answer}</p>
                    </div>
                    <div className="playerTwo">
                      <p>{categoriesPlayerTwo[index].answer}</p>
                    </div>
                    <div className="points">
                      <p>Meus pontos:</p>
                      <input
                        value={poinsInputPlayerOne[index]}
                        onChange={(event) => handlePointsOnChange(event, index)}
                      />
                    </div>
                  </AnsewerBox>
                ))}
              {typeUser === 'paciente' &&
                categoriesPlayerTwo.map((categorieObj, index) => (
                  <AnsewerBox key={index}>
                    <span>{categorieObj.categorie}</span>
                    <div className="playerOne">
                      <p>{categorieObj.answer}</p>
                    </div>
                    <div className="playerTwo">
                      <p>{categoriesPlayerOne[index].answer}</p>
                    </div>
                    <div className="points">
                      <p>Meus pontos:</p>
                      <input
                        value={poinsInputPlayerTwo[index]}
                        onChange={(event) => handlePointsOnChange(event, index)}
                      />
                    </div>
                  </AnsewerBox>
                ))}
            </QuestionBoxWrapper>
            <FinishButtonContainer>
              <NextButton
                enable={shouldEnableNextRound}
                onClick={shouldEnableNextRound ? handleNextRoundOnClick : null}
              >
                Próxima rodada
              </NextButton>
            </FinishButtonContainer>
          </>
        )}
        {page === 6 && (
          <div
            style={{
              height: '290px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <NextButton enable={true} style={{ width: '162px' }} onClick={handleRestart}>
              Jogar novamente
            </NextButton>
          </div>
        )}
      </PageContainer>

      <ModalAddOps
        generatePdf={handleExport}
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        token={token}
      />
      <ModalAlert
        modalIsOpen={modalIsOpenAlert}
        setIsOpen={setIsOpenAlert}
        token={token}
        props={props}
        title={'Stop(adedonha)'}
        recurso={nome_recurso_cod}
        bt01Txt={'Fechar'}
      />
      <ModalConfirm
        title="Limpar o jogo"
        description="Deseja realmente reiniciar o jogo?"
        modalIsOpen={modalCleanIsOpen}
        setIsOpen={setModalCleanIsOpen}
        confirmButtonText="Reiniciar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCleanIsOpen(false)}
        onConfirm={handleRestart}
      />
      <ModalConfirm
        title="Fechar o jogo"
        description="Deseja realmente fechar o jogo?"
        modalIsOpen={modalCloseIsOpen}
        setIsOpen={setModalCloseIsOpen}
        confirmButtonText="Fechar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCloseIsOpen(false)}
        onConfirm={() =>
          handleBackHome(
            modalClose,
            nome_recurso_cod,
            typeUser,
            props,
            os,
            token,
            ambiente
          )
        }
      />
    </Container>
  );
}

export default Stop;
