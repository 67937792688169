import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PageContainer = styled.div`
  width: 90%;
  border-radius: 10px;
  background: #e8e3fc;
  min-height: 457px;
  margin-bottom: 24px;
  padding-bottom: 40px;

  @media (min-width: 786px) {
    width: 841px;
    padding: 20px 0;
    margin-bottom: 0;
  }
`;

export const FirstPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: 20px 0;

  img {
    width: 190px;
  }

  @media (min-width: 786px) {
    padding: 0;
    flex-direction: row;
    justify-content: space-evenly;

    img {
      width: 320px;
    }
  }
`;

export const FirstPageTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;

  h1 {
    font-family: Lobster;
    font-weight: 400;
    font-size: 44px;
    color: #4c5153;
    text-align: center;
    margin-top: 0;
  }

  @media (min-width: 786px) {
    margin-bottom: 0;
    h1 {
      font-size: 64px;
    }
  }
`;

export const GameButtonPrimary = styled.button`
  width: ${(props) => (props.width ? props.width : '122px')};
  height: 40px;
  border: none;
  border-radius: 24px;
  background: #2797ba;
  color: #f5f5f5;
  box-shadow: inset 0px 0px 19px #63d6fa;
  font-family: Nunito;
  font-weight: 900;
  font-size: 18px;
  margin: 0 8px;
  cursor: pointer;
`;

export const GameButtonSecondary = styled.button`
  padding: 6px 24px;
  width: 120px;
  height: 40px;
  background: #f5f5f5;
  border: 2px solid #2797ba;
  box-shadow: inset 0px 0px 19px #e8e9e9;
  border-radius: 24px;
  color: #2797ba;
  font-family: Nunito;
  font-weight: 900;
  font-size: 18px;
  margin: 0 8px;
  cursor: pointer;
`;

export const PlayGameWrapper = styled.div`
  min-height: 387px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-family: Roboto;
    font-weight: 500;
    font-size: 96px;
    color: #2797ba;
    margin: 10px 0 30px;
  }

  h2 {
    font-family: Roboto;
    font-weight: 500;
    font-size: 24px;
    text-align: center;
    color: #4c5153;
  }
`;

export const ScoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.page !== 6 ? 'space-between' : 'center')};
  align-items: flex-start;
  padding: 0 39px 0 21px;
  margin: ${(props) => (props.page === 6 ? '20px 0 0 -20px' : '0')};

  @media (min-width: 786px) {
    flex-direction: row;
    margin: 0;
  }
`;

export const LetterDisplay = styled.div`
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;

  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    align-items: center;
    justify-content: flex-start;
    margin-top: 18px;

    @media (min-width: 786px) {
      margin-top: 0;
    }

    h1 {
      font-family: Roboto;
      font-weight: 500;
      font-size: 24px;
      color: #4c5153;
      margin: 0;
    }

    h2 {
      font-family: Roboto;
      font-weight: 500;
      font-size: 30px;
      color: #2797ba;
      margin: 0;
    }
  }

  h3 {
    font-family: Roboto;
    font-weight: bold;
    font-size: 18px;
    color: #2797ba;
    margin: 0;
  }
`;

export const Score = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ScoreRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-end;

  @media (max-width: 600px) {
    width: 260px;
  }

  h1 {
    font-family: Roboto;
    font-weight: bold;
    font-size: 12px;
    color: #2797ba;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 10px;

    h2 {
      font-family: Roboto;
      font-weight: bold;
      font-size: 12px;
      color: #4c5153;
      margin-top: 6.5px;
    }

    input {
      width: 50px;
      height: 19px;
      border: 1px solid #ccd1d3;
      border-radius: 5px;
      text-align: center;
      font-family: Roboto;
      font-weight: normal;
      font-size: 12px;
      color: #7d8c94;
    }
  }

  @media (min-width: 786px) {
    width: 100%;
  }
`;

export const QuestionBoxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  position: relative;
  margin-bottom: 50px;
`;

export const QuestionBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 152px;
  height: 107px;
  border-radius: 5px;
  background: #ffffff;
  border: 1px solid #ccd1d3;
  margin: 18px 5px;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 143px;
    height: 36px;
    background: #f5f5f5;
    color: #4c5153;
    text-align: center;
    border-radius: 5px;
    padding: 2px;
  }

  input {
    width: 76px;
    height: 24px;
    background: transparent;
    border: none;
    color: #4c5153;
    font-family: Roboto;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
  }
`;

export const StopButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 17px;
  margin-top: 20px;
  position: relative;

  @media (min-width: 786px) {
    align-items: flex-end;
    justify-content: flex-end;
  }

  button {
    width: 151px;
    height: 43px;
    border-radius: 4px;
    background: #f44336;
    color: #f5f5f5;
    font-family: Roboto;
    font-weight: bold;
    font-size: 18px;
    border: none;
    cursor: pointer;
  }
`;

export const AnimationText = styled.h1`
  font-family: Roboto;
  font-weight: 500;
  font-size: 110px;
  color: #f44336;
  position: absolute;
  bottom: -60px;

  @media (min-width: 550px) {
    font-size: 200px;
  }
`;

export const AnsewerBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 8px 5px;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 143px;
    height: 36px;
    background: #f5f5f5;
    border-radius: 5px;
    padding: 2px;
    font-family: Roboto;
    font-weight: bold;
    font-size: 16px;
    color: #4c5153;
    text-align: center;
  }

  .playerOne,
  .playerTwo {
    width: 143px;
    height: 36px;
    border-radius: 5px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      font-family: Roboto;
      font-weight: normal;
      font-size: 16px;
      text-align: center;
    }
  }

  .playerOne {
    background: #ddf2f8;
    border: 1px solid #2797ba;

    p {
      color: #4c5153;
    }
  }

  .playerTwo {
    background: #e8e3fc;
    border: 1px solid #f44336;

    p {
      color: #90a4ae;
    }
  }

  .points {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    input {
      width: 39px;
      height: 24px;
      background: #ffffff;
      border: 1px solid #ccd1d3;
      border-radius: 5px;
      font-family: Roboto;
      font-weight: normal;
      font-size: 14px;
      color: #4c5153;
      margin-left: 16px;
      text-align: center;
    }
  }
`;

export const NextButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 786px) {
    align-items: flex-end;
    justify-content: flex-end;
  }

  button {
    width: 151px;
    height: 43px;
    border-radius: 4px;
    background: #f44336;
    color: #f5f5f5;
    font-family: Roboto;
    font-weight: bold;
    font-size: 18px;
    border: none;
    cursor: pointer;
  }
`;

export const FinishButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 786px) {
    width: calc(100% - 30px);
    align-items: flex-end;
    justify-content: flex-end;
  }
`;

export const NextButton = styled.button`
  width: 151px;
  height: 43px;
  border-radius: 4px;
  background: ${(props) => (props.enable ? '#2797ba' : '#CCD1D3')};
  color: #f5f5f5;
  font-family: Roboto;
  font-weight: bold;
  font-size: 18px;
  border: none;
  cursor: ${(props) => (props.enable ? 'pointer' : 'default')};
`;

export const Timer = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 125px;
  bottom: 52px;

  @media (min-width: 786px) {
    right: 35px;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 47px;
    height: 45px;
    border-radius: 2px;
    background: #f5f5f5;
    border: 1px solid #2797ba;
  }

  h1 {
    font-family: Roboto;
    font-weight: normal;
    font-size: 32px;
    color: #2797ba;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    width: 20px;

    p {
      font-size: 34px;
      color: #7d8c93;
    }
  }
`;

export const SaveArea = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 8px 0 33px;
  flex-wrap: wrap;
`;
