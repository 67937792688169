import React, { useState, useEffect } from 'react';

import { GoSearch } from 'react-icons/go';
import { Link } from 'react-router-dom';

import { getSessaoAtual, getPacients } from '../../services/apiService';

import {
  Modal,
  Container,
  TitleContainer,
  CentralContainer,
  ColCenter,
  ButtonsContainer,
  CancelButton,
  YesButton,
  LinkContainer,
  CopyButton,
  CopiedSnack,
  InputContainer,
  InputField,
  ListContainer,
  ListItem,
} from './styles';

import { IoClose } from 'react-icons/io5';

export default function ModalStartResource({
  isOpen,
  setIsOpen,
  content,
  os,
  token,
  ambiente,
  tokenSocket,
}) {
  const [currentScreen, setCurrentScreen] = useState('hasNotPacient');
  const [animate, setAnimate] = useState();
  const [searchNameInput, setSearchNameInput] = useState('');
  const [sessions, setSessions] = useState();
  const [allPacients, setAllPacients] = useState([]);
  const [searchedPacients, setSearchedPacients] = useState(allPacients);
  const [selectedPacient, setSelectedPacient] = useState([]);

  const closeModal = () => {
    if (sessions.length !== 0) {
      setCurrentScreen('hasPacient');
    } else {
      setCurrentScreen('hasNotPacient');
    }
    setAnimate(undefined);
    setIsOpen(false);
  };

  const listSessaoAtual = async () => {
    await getSessaoAtual(os, token)
      .then((resp) => {
        setSessions(resp.data.records);
        if (resp.data.records.length !== 0 && !resp.data.records.error) {
          setCurrentScreen('hasPacient');
          setSelectedPacient(resp.data.records);
        }
      })
      .catch((err) => {
        console.log('Houve um erro ao buscar a sessão atual');
      });
  };

  const listPacient = async () => {
    await getPacients(os, token)
      .then((resp) => {
        //console.log(resp.data.records);
        setAllPacients(resp.data.records);
        setSearchedPacients(resp.data.records);
      })
      .catch((err) => {
        console.log('Houve um erro ao listar todos os pacientes');
      });
  };

  const setBaseLocal = (data) => {
    localStorage.setItem('@token', token);
    localStorage.setItem('@cod_paciente', data.cod_paciente);
    localStorage.setItem('@cod_sessao', data.cod_sessao);
    localStorage.setItem('@data', data.data);
    localStorage.setItem('@hora', data.hora);
    localStorage.setItem('@nome_paciente', data.nome_paciente);
    localStorage.setItem('@obs', '');
  };

  const searchName = (searchedName) => {
    //console.log('Pacientes buscados' + searchedPacients);
    //console.log(allPacients);
    if (searchedName === '') {
      setSearchedPacients(allPacients);
    } else {
      let filtered = allPacients.filter((pacient) =>
        pacient.nome_paciente.toLowerCase().includes(searchedName.toLowerCase())
      );

      setSearchedPacients(filtered);
    }
  };

  const copyURL = async () => {
    let copyText = document.getElementById('link-url');
    let temp = document.createElement('textarea');
    document.body.appendChild(temp);
    temp.value = copyText.innerText;
    temp.select();
    document.execCommand('copy');
    document.body.removeChild(temp);
  };

  useEffect(() => {
    listPacient();
    listSessaoAtual();
  }, []);

  return (
    <Modal isOpen={isOpen}>
      <Container>
        <TitleContainer>
          <h1>Recurso Terapêutico</h1>
          <IoClose
            size={30}
            color="#7D8C94"
            onClick={() => closeModal()}
            style={{ cursor: 'pointer' }}
          />
        </TitleContainer>
        {currentScreen === 'hasPacient' && (
          <>
            <CentralContainer>
              <h2>De acordo com sua agenda, o paciente desse horário é:</h2>
              <ColCenter>
                <h1>{selectedPacient.nome_paciente}</h1>
                <h2>O paciente acima é o que está atendendo neste momento?</h2>
              </ColCenter>
            </CentralContainer>
            <ButtonsContainer>
              <CancelButton onClick={() => setCurrentScreen('hasNotPacient')}>
                <h3>Não</h3>
              </CancelButton>
              <YesButton
                onClick={() => {
                  setBaseLocal(sessions);
                  if (ambiente && ambiente === 'salavirtual') {
                    closeModal();
                    window.location.href = `/${content.link}/profissional/${os}/${token}/${tokenSocket}/${ambiente}`;
                  } else {
                    setCurrentScreen('linkScreen');
                  }
                }}
              >
                <h3>Sim</h3>
              </YesButton>
            </ButtonsContainer>
          </>
        )}
        {currentScreen === 'linkScreen' && (
          <>
            <h2>
              Para iniciar o recurso copie o link abaixo, passe para seu
              paciente e clique no botão Iniciar recurso.
            </h2>

            <LinkContainer>
              <h2 id="link-url">
                {`https://jogos.terapiainterativa.com.br/${content.link}/paciente/${os}/${tokenSocket}`}
              </h2>
            </LinkContainer>
            <CopyButton
              onClick={() => {
                animate === undefined ? setAnimate(true) : setAnimate(!animate);
                copyURL();
              }}
            >
              <h2>Copiar link</h2>
            </CopyButton>
            <div style={{ minHeight: '80px' }}>
              <CopiedSnack animate={animate}>
                <h2>Link copiado</h2>
              </CopiedSnack>
            </div>
            <ButtonsContainer>
              <CancelButton
                onClick={() => {
                  setCurrentScreen('hasPacient');
                  setAnimate(undefined);
                }}
              >
                <h3>Voltar</h3>
              </CancelButton>
              <YesButton style={{ width: '150px' }}>
                <Link
                  to={`/${content.link}/profissional/${os}/${token}/${tokenSocket}/${ambiente}`}
                  style={{ textDecoration: 'none' }}
                >
                  <h3>Iniciar recurso</h3>
                </Link>
              </YesButton>
            </ButtonsContainer>
          </>
        )}
        {currentScreen === 'hasNotPacient' && (
          <>
            <CentralContainer>
              <h2>Escolha o paciente que realizará o recurso terapêutico:</h2>
              <InputContainer>
                <GoSearch size={26} color={'#AEB9BF'} />
                <InputField
                  value={searchNameInput}
                  spellCheck={false}
                  onChange={(e) => {
                    setSearchNameInput(e.target.value);
                    searchName(e.target.value);
                  }}
                  placeholder="Buscar cliente"
                />
              </InputContainer>

              <ListContainer>
                {searchedPacients.length > 0 &&
                  searchedPacients.map((pacient, index) => {
                    return (
                      <ListItem
                        pair={index % 2 === 0}
                        onClick={() => {
                          setBaseLocal(pacient);
                          setSelectedPacient(pacient);
                        }}
                        isSelected={
                          selectedPacient.cod_paciente === pacient.cod_paciente
                        }
                      >
                        <h2 style={{ cursor: 'pointer' }}>
                          {pacient.nome_paciente}
                        </h2>
                      </ListItem>
                    );
                  })}
              </ListContainer>
            </CentralContainer>
            <ButtonsContainer>
              <CancelButton onClick={() => closeModal()}>
                <h3>Cancelar</h3>
              </CancelButton>
              {ambiente !== 'salavirtual' && (
                <YesButton
                  onClick={() => {
                    if (selectedPacient.length !== 0) {
                      setCurrentScreen('linkScreen');
                    }
                  }}
                  disabled={selectedPacient.length === 0}
                >
                  <h3>Avançar</h3>
                </YesButton>
              )}
              {ambiente === 'salavirtual' && (
                <YesButton style={{ width: '150px' }}>
                  <Link
                    to={`/${content.link}/profissional/${os}/${token}/${tokenSocket}/${ambiente}`}
                    style={{ textDecoration: 'none' }}
                  >
                    <h3>Iniciar recurso</h3>
                  </Link>
                </YesButton>
              )}
            </ButtonsContainer>
          </>
        )}
      </Container>
    </Modal>
  );
}
