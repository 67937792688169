import { useState, useRef, useEffect } from 'react';

import { cubes } from '@assets';

import domtoimage from 'dom-to-image';

import {
  modalOpen,
  modalClose,
  handleBackHome,
  sendArquivo,
  startSocket,
  downloadURI,
  DataURL,
} from '../../utils';

import {
  ModalAddOps,
  ModalAlert,
  SecondaryButton,
  TertiaryButton,
  ModalConfirm,
} from '../../components';

import {
  Container,
  CubesContainer,
  CubeWrapper,
  NumberInput,
  SaveArea,
} from './styles';

function ConnectTheCubes(props) {
  const {
    nome_recurso_cod,
    typeUser,
    os,
    token,
    tokenSocketIo,
    ambiente,
  } = DataURL(props);

  const [yourID, setYourID] = useState();
  const socketRef = useRef();

  //Modal variables
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenAlert, setIsOpenAlert] = useState(false);
  const [modalCleanIsOpen, setModalCleanIsOpen] = useState(false);
  const [modalCloseIsOpen, setModalCloseIsOpen] = useState(false);

  // resource variables
  const [answerBox, setAnswerBox] = useState(shuffle([...cubes]));
  const [numbersInput, setNumbersInput] = useState({
    input1: '',
    input2: '',
    input3: '',
    input4: '',
    input5: '',
    input6: '',
    input7: '',
    input8: '',
    input9: '',
    input10: '',
    input11: '',
    input12: '',
    input13: '',
    input14: '',
    input15: '',
    input16: '',
    input17: '',
    input18: '',
  });

  // randomizer function
  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex;

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  // onEvent handlers
  function handleInputOnChange(e, key) {
    const newObj = { ...numbersInput };

    newObj[key] = e.target.value;

    setNumbersInput(newObj);
    sendInformation(newObj, 'sendNumbers');
  }

  function handleClean() {
    const newAnswerBox = shuffle([...cubes]);
    setAnswerBox(newAnswerBox);

    const newNumbersInput = { ...numbersInput };

    for (let i = 1; i < 19; i++) {
      newNumbersInput[`input${i}`] = '';
    }
    changeInputValue(newNumbersInput);

    sendInformation(newNumbersInput, 'sendNumbers');
    sendInformation(newAnswerBox, 'updateBox');
    setModalCleanIsOpen(false);
  }

  function changeInputValue(obj) {
    for (let i = 1; i < 19; i++) {
      document.getElementById(`input${i}`).value = obj[`input${i}`];
    }
  }

  //socket functions
  function sendInformation(information, typeOfMessage) {
    const messageObject = {
      body: {
        information,
        typeOfMessage,
      },
      id: yourID,
      typeUser,
    };
    socketRef.current.emit('send message', tokenSocketIo, messageObject);
  }

  function receivedMessage(message) {
    const information = message.body.information;
    const isInformationBox = message.body.typeOfMessage === 'updateBox';

    if (isInformationBox) {
      setAnswerBox(information);
    } else {
      setNumbersInput(information);
      changeInputValue(information);
    }
  }

  useEffect(() => {
    startSocket(socketRef, setYourID, receivedMessage, tokenSocketIo, typeUser);
    sendInformation(answerBox, 'updateBox');
    if (typeUser === 'profissional') {
      modalOpen(nome_recurso_cod, tokenSocketIo);
    }
  }, []);

  // modal function
  function generatePdf() {
    domtoimage
      .toPng(document.getElementById('divIdToPrint'))
      .then(function (dataUrl) {
        const nome_paciente = localStorage
          .getItem('@nome_paciente')
          .replaceAll(' ', '_');
        const filename = `TerapiaInterativa-${nome_recurso_cod}-${nome_paciente}.png`;
        downloadURI(
          dataUrl,
          filename,
          sendArquivo,
          os,
          token,
          nome_recurso_cod
        );

        setIsOpen(false);
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  }

  return (
    <Container>
      <SaveArea>
        {typeUser === 'profissional' && (
          <TertiaryButton mobile onClick={() => setIsOpenAlert(true)}>
            Instruções
          </TertiaryButton>
        )}
        {typeUser === 'profissional' && (
          <TertiaryButton mobile onClick={() => setModalCloseIsOpen(true)}>
            Fechar
          </TertiaryButton>
        )}
        {typeUser === 'profissional' && (
          <TertiaryButton mobile onClick={() => setModalCleanIsOpen(true)}>
            Recomeçar
          </TertiaryButton>
        )}
        {typeUser === 'profissional' && (
          <SecondaryButton mobile onClick={() => setIsOpen(true)}>
            Salvar
          </SecondaryButton>
        )}
      </SaveArea>
      <h1>Ligue os Cubos</h1>
      <CubesContainer id="divIdToPrint">
        <div>
          {cubes.map((cube, index) => (
            <CubeWrapper>
              <img src={cube} alt="cube" />
              <NumberInput type="text" value={index + 1} readOnly />
            </CubeWrapper>
          ))}
        </div>
        <div>
          {answerBox.map((cube, index) => (
            <CubeWrapper>
              <NumberInput
                type="text"
                answerCubes
                onChange={(e) => handleInputOnChange(e, `input${index + 1}`)}
                id={`input${index + 1}`}
              />
              <img src={cube} alt="cube" />
            </CubeWrapper>
          ))}
        </div>
      </CubesContainer>

      <ModalAddOps
        generatePdf={generatePdf}
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        token={token}
      />
      <ModalAlert
        modalIsOpen={modalIsOpenAlert}
        setIsOpen={setIsOpenAlert}
        token={token}
        props={props}
        title={'Ligue os cubos'}
        recurso={nome_recurso_cod}
        bt01Txt={'Fechar'}
      />
      <ModalConfirm
        title="Limpar o jogo"
        description="Deseja realmente recomeçar o jogo?"
        modalIsOpen={modalCleanIsOpen}
        setIsOpen={setModalCleanIsOpen}
        confirmButtonText="Recomeçar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCleanIsOpen(false)}
        onConfirm={handleClean}
      />
      <ModalConfirm
        title="Fechar o jogo"
        description="Deseja realmente fechar o jogo?"
        modalIsOpen={modalCloseIsOpen}
        setIsOpen={setModalCloseIsOpen}
        confirmButtonText="Fechar"
        cancelButtonText="Cancelar"
        onCancel={() => setModalCloseIsOpen(false)}
        onConfirm={() =>
          handleBackHome(
            modalClose,
            nome_recurso_cod,
            typeUser,
            props,
            os,
            token,
            ambiente
          )
        }
      />
    </Container>
  );
}

export default ConnectTheCubes;
