import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    background: #f5f5f5;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ScoreContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 395px;
  margin: 20px 0 0;

  @media (max-width: 786px) {
    width: 320px;
  }

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 165px;
    height: 35px;
    padding: 4px;
    border-radius: 5px;
    background: #e8e3fc;
    cursor: pointer;
    transition: 0.25s;

    @media (max-width: 786px) {
      width: 140px;
    }

    h2 {
      margin-right: 8px;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;
      color: #4c5153;
      font-family: 'roboto';
    }
  }
`;

export const TurnBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-size: 14px;
    font-weight: 400;
  }
`;

export const Table = styled.div`
  display: grid;
  position: relative;
  z-index: 99;
  grid-template-rows: 110px 110px 110px;
  grid-template-columns: 110px 110px 110px;
  height: 355px;
  width: 355px;
  padding: 10px;
  justify-content: center;
  align-content: center;
  transition: z-index 1s ease, height 1s ease;
  background: #e8e3fc;
  border-radius: 8px;

  @media (max-width: 786px) {
    height: 292px;
    width: 292px;
    grid-template-rows: 90px 90px 90px;
    grid-template-columns: 90px 90px 90px;
  }

  .remove-left-border {
    border-left: 1px solid transparent;
  }

  .remove-right-border {
    border-right: 1px solid transparent;
  }

  .remove-top-border {
    border-top: 1px solid transparent;
  }

  .remove-bottom-border {
    border-bottom: 1px solid transparent;
  }
`;

export const TableButton = styled.div`
  border: 5px solid #7d8c94;
  padding: 20px;
  text-align: center;
  align-content: center;
  transition: all 1s ease;
  color: black;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.cursor === true ? 'pointer' : 'auto')};
`;

export const SaveArea = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 8px 0;

  @media (max-width: 786px) {
    flex-wrap: wrap;
  }
`;

export const WaitToPlayBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 355px;
  width: 355px;
  background: #e8e3fc;
  border-radius: 8px;

  h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    color: #4c5153;
    text-align: center;
    margin-top: 0;
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 786px) {
    height: 292px;
    width: 292px;
  }
`;
