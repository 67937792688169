import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    background: #f5f5f5;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 100vh;

  @media (max-width: 870px) {
    justify-content: flex-start;
    flex-direction: column;
  }
`;

export const ThermometerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ThermometerBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 178px;
  height: 507px;
  padding-top: 10px;
  background-size: cover;
  background-position: center;

  img {
    width: 136px;
    margin-top: 70px;
  }
`;

export const EmotionButton = styled.button`
  width: 108px;
  height: 34px;
  margin-top: 7px;
  border: none;
  border-radius: 4px;
  font-size: 10px;
  font-weight: 900;
  color: #ffffff;
  background-color: ${(props) => props.background};
  box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;
  position: relative;
`;

export const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 870px) {
    align-items: center;
    margin-top: 20px;
  }
`;

export const Question = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  p {
    font-size: 14px;
    font-weight: 700;
    color: #677176;
  }

  textarea {
    width: 428px;
    height: 45px;
    border-radius: 5px;
    background: #e8e9e9;
    border: 1px solid #90a4ae;
    padding-left: 10px;
    resize: none;

    @media (max-width: 870px) {
      width: 100%;
      height: 60px;
    }
  }

  @media (max-width: 870px) {
    align-items: center;
    width: 90%;
  }
`;

export const ThermometerTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 870px) {
    flex-direction: column;
    height: 50px;
    flex-direction: column;
    justify-content: space-around;
  }

  h1 {
    font-size: 17px;
    font-weight: 900;
    line-height: 12px;
    color: #4c5153;
    margin: 0px;
  }

  h2 {
    font-size: 17px;
    font-weight: 900;
    line-height: 12px;
    padding-left: 4px;
    margin: 0px;
  }
`;

export const HoverBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 46px;
  background: transparent;
  border-right: 8px solid #ffffff;
  border-radius: 5px;
  box-shadow: inset 121px 0px 0px 5px #fff;
  position: absolute;
  top: -7px;
  right: -11px;
  color: #4c5153;

  @media (max-width: 786px) {
    width: 210px;
    box-shadow: inset 92px 0px 0px 5px #fff;
  }

  h2 {
    font-size: 14px;
    font-weight: 900;
    text-align: center;
    max-width: 118px;
    color: #4c5153;

    padding-right: 12px;
    @media (max-width: 786px) {
      font-size: 12px;
      max-width: 90px;
    }
  }

  div {
    width: 101px;
    height: 30px;
    background: transparent !important;
  }
`;

export const SaveArea = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 20px 0 24px 0;
`;
